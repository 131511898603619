import actions from "../summary/action";
import authActions from "./action";

const initislState = {
  token: window.localStorage.getItem("token"),
  user: {},
  authMessage: "",
  rawError: {},
};

export default function authReducer(state = initislState, action) {
  switch (action.type) {
    case authActions.SIGNIN_SUCCESS:
      window.localStorage.setItem("token", action.token);
      window.localStorage.setItem("user-id", action.id);
      return {
        ...state,
        token: action.token,
      };
    case authActions.AUTH_ERROR:
      return {
        ...state,
        token: null,
        authMessage: action.message,
        rawError: action.rawError,
      };
    case authActions.SIGNOUT:
      window.localStorage.removeItem("token");
      return {
        ...state,
        token: null,
      };
    case authActions.SET_USER:
      return {
        ...state,
        user: action.user,
      };
    default:
      return state;
  }
}
