export function getThemesUrl(skip = 0, limit = 10) {
  return `api/theme/?limit=${limit}&skip=${skip}`;
}

export function getSingleThemeUrl(themeId) {
  return `api/theme/${themeId}`;
}

export function getDataUrl(themeId, skip = 0, limit = 10) {
  return `api/theme/${themeId}/data?limit=${limit}&skip=${skip}`;
}

export function createThemeUrl() {
  return `api/theme/`;
}

export function updateThemeUrl(themeId) {
  return `api/theme/${themeId}`;
}

export function updateActionStepUrl(themeId) {
  return `api/theme/${themeId}/actionsteps`;
}

export function startThemeScrapperUrl(themeId, isTest = false) {
  return `api/theme/${themeId}/start?test=${isTest}`;
}

export function stopThemeScrapperUrl(themeId) {
  return `api/theme/${themeId}/stop`;
}

export function importUrlsUrl(themeId) {
  return `api/theme/${themeId}/import`;
}

export function removeAllSitesUrl(themeId) {
  return `api/theme/${themeId}/removeallsites`;
}

export function themeToggleJSUrl(themeId) {
  return `api/theme/${themeId}/toggle-js`;
}
