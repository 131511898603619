const actions = {
  GET_SUMMARY: "GET_SUMMARY",
  SET_SUMMARY: "SET_SUMMARY",
  GET_TIMEOUT: "GET_TIMEOUT",
  SET_TIMEOUT: "SET_TIMEOUT",
  SET_TIMEOUT_RESULT: "SET_TIMEOUT_RESULT",
  SET_OFFS3: "SET_OFFS3",
  SET_OFFS3_RESULT: "SET_OFFS3_RESULT",

  getSummary: () => (dispatch) => {
    dispatch({ type: actions.GET_SUMMARY });
  },

  getTimeout: () => (dispatch, getState) => {
    console.log(getState());
    const user_id = window.localStorage.getItem("user-id");
    dispatch({ type: actions.GET_TIMEOUT, userId: user_id });
  },

  setTimeout: (timeout) => (dispatch, getState) => {
    const user_id = window.localStorage.getItem("user-id");
    dispatch({
      type: actions.SET_TIMEOUT,
      timeout,
      userId: user_id,
    });
  },

  toggleOffS3: () => (dispatch, getState) => {
    const user_id = window.localStorage.getItem("user-id");
    const offS3 = getState().Summary.offS3;
    dispatch({
      type: actions.SET_OFFS3,
      offS3: !offS3,
      userId: user_id,
    });
  },
};

export default actions;
