import actions from "./action";

const initialState = {
  site_avg_count: 0,
  total_cars_count: 0,
  total_sites_count: 0,
  site_no_theme_count: 0,
  total_themes: 0,
  sold_cars_count: 0,
  instock_cars_count: 0,
  configured: 0,
  unconfigured: 0,
  partial_configured: 0,
  dead_sites_count: 0,
  crashed_site_count: 0,
  timeout: 300,
  offS3: false,
  cycle_times: {},
  working_sites: 0,
  not_working_sites: 0,
};

export default function sitesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_SUMMARY:
      return {
        ...state,
        ...action.data,
      };
    case actions.SET_TIMEOUT_RESULT:
      return {
        ...state,
        timeout: action.timeout,
      };
    case actions.SET_OFFS3_RESULT:
      return {
        ...state,
        offS3: action.offS3,
      };
    default:
      return state;
  }
}
