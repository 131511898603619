export function signInUrl() {
  return "api/auth/login/";
}

export function signUpUrl() {
  return "api/users/";
}

export function singleUserUrl(userId) {
  return `api/users/${userId}`;
}
