import { all, takeLatest, put, call } from "redux-saga/effects";
import themeActions from "./action";
import appActions from "../app/action";
import { apiCall } from "../http";
import {
  getThemesUrl,
  getSingleThemeUrl,
  getDataUrl,
  createThemeUrl,
  updateThemeUrl,
  updateActionStepUrl,
  startThemeScrapperUrl,
  stopThemeScrapperUrl,
  removeAllSitesUrl,
  themeToggleJSUrl,
} from "../../utils/themesUrls";
import { notification } from "antd";

export function* getThemes({ skip, limit }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    const response = yield call(() => apiCall(getThemesUrl(skip, limit)));
    yield put({
      type: themeActions.SET_THEMES,
      themes: response.themes,
      count: response.total,
    });
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export function* getSingleTheme({ themeId, themes }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    let theme = yield call(() => apiCall(getSingleThemeUrl(themeId)));
    theme.actionSteps.map((step) => {
      delete step._id;
      return null;
    });
    themes.map((s, idx) => {
      if (s._id === themeId) themes[idx] = theme;
      return null;
    });
    yield put({
      type: themeActions.SET_THEMES,
      themes: themes,
    });
    yield put({
      type: themeActions.SET_ACTIVE_THEME,
      theme: theme,
    });
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export function* getData({ themeId, skip, limit }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    const response = yield call(() =>
      apiCall(getDataUrl(themeId, skip, limit))
    );
    yield put({
      type: themeActions.SET_DATA,
      data: response,
    });
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export function* createTheme({ theme, themes }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    let new_theme = yield call(() => apiCall(createThemeUrl(), "POST", theme));
    themes.unshift(new_theme);
    yield put({
      type: themeActions.SET_THEMES,
      themes: themes,
    });
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export function* updateTheme({ theme, themes }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    yield call(() => apiCall(updateThemeUrl(theme._id), "PUT", theme));
    themes.map((s, idx) => {
      if (s._id === theme._id) themes[idx] = theme;
      return null;
    });
    yield put({
      type: themeActions.SET_THEMES,
      themes: themes,
    });
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export function* deleteTheme({ theme, themes }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    yield call(() => apiCall(updateThemeUrl(theme._id), "DELETE", theme));
    let s_idx = -1;
    themes.map((s, idx) => {
      if (s._id === theme._id) s_idx = idx;
      return null;
    });
    themes.splice(s_idx, 1);
    yield put({
      type: themeActions.SET_THEMES,
      themes: themes,
    });
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export function* updateActionStep({ themeId, steps, themes }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    yield call(() =>
      apiCall(updateActionStepUrl(themeId), "POST", {
        actionSteps: JSON.stringify(steps),
      })
    );
    let s = themes.filter((s) => s._id === themeId);
    s.actionSteps = steps;
    notification.success({
      message: "Action Steps saved.",
    });
    yield put({
      type: themeActions.SET_THEMES,
      themes: themes,
    });
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export function* startThemeScrapper({ themeId, isTest }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    notification.info({
      message: "Starting Scrapper...",
    });
    yield call(() => apiCall(startThemeScrapperUrl(themeId, isTest), "POST"));
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export function* stopThemeScrapper({ themeId }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    notification.info({
      message: "Stopping Scrapper...",
    });
    yield call(() => apiCall(stopThemeScrapperUrl(themeId), "POST"));
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export function* removeAllSites({ themeId, themes }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    yield call(() => apiCall(removeAllSitesUrl(themeId), "DELETE"));
    yield put({
      type: themeActions.GET_SINGLE_THEME,
      themeId,
      themes,
    });
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export function* toggleThemeJS({ theme, themes }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    yield call(() => apiCall(themeToggleJSUrl(theme._id), "POST"));
    themes.map((s, idx) => {
      if (s._id === theme._id) themes[idx] = theme;
      return null;
    });
    theme.javascript = !theme.javascript;
    yield put({
      type: themeActions.SET_THEMES,
      themes: themes,
    });
    yield put({
      type: themeActions.SET_ACTIVE_THEME,
      theme: theme,
    });
    notification.info({
      message: "Toggled Javascript handle",
    });
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(themeActions.GET_THEMES, getThemes),
    yield takeLatest(themeActions.GET_SINGLE_THEME, getSingleTheme),
    yield takeLatest(themeActions.GET_DATA, getData),
    yield takeLatest(themeActions.CREATE_THEME, createTheme),
    yield takeLatest(themeActions.UPDATE_THEME, updateTheme),
    yield takeLatest(themeActions.DELETE_THEME, deleteTheme),
    yield takeLatest(themeActions.UPDATE_ACTION_STEP, updateActionStep),
    yield takeLatest(themeActions.START_THEME_SCRAPPER, startThemeScrapper),
    yield takeLatest(themeActions.STOP_THEME_SCRAPPER, stopThemeScrapper),
    yield takeLatest(themeActions.REMOVE_ALL_SITES, removeAllSites),
    yield takeLatest(themeActions.TOGGLE_THEME_JS, toggleThemeJS),
  ]);
}
