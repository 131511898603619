import settings from "../setting";

const actions = {
  GET_SITES: "GET_SITES",
  GET_SINGLE_SITE: "GET_SINGLE_SITE",
  SET_SITES: "SET_SITES",
  CREATE_SITE: "CREATE_SITE",
  UPDATE_SITE: "UPDATE_SITE",
  DELETE_SITE: "DELETE_SITE",
  UPDATE_SCHEDULE: "UPDATE_SCHEDULE",
  RUN_SITE: "RUN_SITE",
  STOP_SITE: "STOP_SITE",
  UPDATE_ACTION_STEP: "SITE_UPDATE_ACTION_STEP",
  GET_DATA: "GET_DATA",
  SET_DATA: "SET_DATA",
  DELETE_DATA: "DELETE_DATA",
  SET_ACTIVE_SITE: "SET_ACTIVE_SITE",
  START_SITE_SCRAPPER: "START_SITE_SCRAPPER",
  STOP_SITE_SCRAPPER: "STOP_SITE_SCRAPPER",
  TOGGLE_SITE_JS: "TOGGLE_SITE_JS",
  TOGGLE_SITE_DEAD: "TOGGLE_SITE_DEAD",
  SET_PAGE_FILTERS: "SET_PAGE_FILTERS",
  CLEAR_CARS: "CLEAR_CARS",

  getSites:
    (
      skip = 0,
      limit = settings.pageSize,
      query,
      themeId,
      sort,
      filterCrashed,
      filterDead,
      filterWorking,
      filterConfigured
    ) =>
    (dispatch) => {
      dispatch({
        type: actions.GET_SITES,
        skip,
        limit,
        query,
        themeId,
        sort,
        filterCrashed,
        filterDead,
        filterWorking,
        filterConfigured,
      });
    },

  createSite: (site) => (dispatch, getState) => {
    let sites = [...getState().Sites.sites];
    dispatch({ type: actions.CREATE_SITE, site, sites });
  },

  updateSite: (site) => (dispatch, getState) => {
    let sites = [...getState().Sites.sites];
    dispatch({ type: actions.UPDATE_SITE, site, sites });
  },

  toggleSiteJS: (site) => (dispatch, getState) => {
    let sites = [...getState().Sites.sites];
    dispatch({ type: actions.TOGGLE_SITE_JS, site, sites });
  },

  toggleSiteDead: (site) => (dispatch, getState) => {
    let sites = [...getState().Sites.sites];
    dispatch({ type: actions.TOGGLE_SITE_DEAD, site, sites });
  },

  deleteSite: (site) => (dispatch, getState) => {
    let sites = [...getState().Sites.sites];
    dispatch({ type: actions.DELETE_SITE, site, sites });
  },

  runSite: (siteId) => (dispatch) => {
    dispatch({ type: actions.RUN_SITE, siteId });
  },

  stopSite: (siteId) => (dispatch) => {
    dispatch({ type: actions.STOP_SITE, siteId });
  },

  setActiveSite: (site) => (dispatch) => {
    dispatch({ type: actions.SET_ACTIVE_SITE, site });
  },

  getData:
    (siteId, skip = 0, limit = settings.pageSize) =>
    (dispatch) => {
      dispatch({ type: actions.GET_DATA, siteId, skip, limit });
    },

  deleteData: (siteId) => (dispatch) => {
    dispatch({ type: actions.DELETE_DATA, siteId });
  },

  updateActionStep: (siteId, steps) => (dispatch, getState) => {
    let sites = [...getState().Sites.sites];
    dispatch({ type: actions.UPDATE_ACTION_STEP, siteId, steps, sites });
  },

  updateSiteSchedule: (siteId, schedule) => (dispatch, getState) => {
    let sites = [...getState().Sites.sites];
    dispatch({ type: actions.UPDATE_SCHEDULE, siteId, schedule, sites });
  },

  startSiteScrapper: (siteId, isTest) => (dispatch) => {
    dispatch({ type: actions.START_SITE_SCRAPPER, siteId, isTest });
  },

  stopSiteScrapper: (siteId) => (dispatch) => {
    dispatch({ type: actions.STOP_SITE_SCRAPPER, siteId });
  },

  reloadSingleSite: (siteId) => (dispatch, getState) => {
    let sites = [...getState().Sites.sites];
    dispatch({ type: actions.GET_SINGLE_SITE, siteId, sites });
  },

  setPageFilters: (filters) => (dispatch) => {
    dispatch({ type: actions.SET_PAGE_FILTERS, filters });
  },

  clearCars: () => (dispatch) => {
    dispatch({
      type: actions.CLEAR_CARS,
    });
  },
};

export default actions;
