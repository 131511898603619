export function getSitesUrl(
  skip = 0,
  limit = 10,
  query,
  themeId,
  sort,
  filterCrashed,
  filterDead,
  filterWorking,
  filterConfigured
) {
  return (
    `api/sites/?limit=${limit}&skip=${skip}&query=${query}&themeId=${themeId}&sort=${JSON.stringify(
      sort
    )}&crashed=${filterCrashed}` +
    `&dead=${filterDead}&working=${filterWorking}&configured=${filterConfigured}`
  );
}

export function clearCarUrl() {
  return "api/sites/clearCars";
}

export function getSingleSiteUrl(siteId) {
  return `api/sites/${siteId}`;
}

export function getDataUrl(siteId, skip = 0, limit = 10) {
  return `api/sites/${siteId}/data?limit=${limit}&skip=${skip}`;
}

export function deleteDataUrl(siteId) {
  return `api/sites/${siteId}/data`;
}

export function createSiteUrl() {
  return `api/sites/`;
}

export function updateSiteUrl(siteId) {
  return `api/sites/${siteId}`;
}

export function updateActionStepUrl(siteId) {
  return `api/sites/${siteId}/actionsteps`;
}

export function updateScheduleUrl(siteId) {
  return `api/sites/${siteId}/schedule`;
}

export function startSiteScrapperUrl(siteId, isTest = false) {
  return `api/sites/${siteId}/start?test=${isTest}`;
}

export function stopSiteScrapperUrl(siteId) {
  return `api/sites/${siteId}/stop`;
}

export function siteToggleJSUrl(siteId) {
  return `api/sites/${siteId}/toggle-js`;
}

export function siteToggleDeadUrl(siteId) {
  return `api/sites/${siteId}/toggle-dead`;
}
