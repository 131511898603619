import React from "react";
import {
  Typography,
  Row,
  Col,
  Button,
  Tooltip,
  Dropdown,
  Menu,
  Table,
  Divider,
  Input,
  Select,
  Icon,
  Tag,
  Modal,
} from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import SiteForm from "../../components/site-form";
import { toTitleCase, statusColors } from "../../utils/libs";
import siteActions from "../../store/sites/action";
import themeActions from "../../store/themes/action";
import summaryActions from "../../store/summary/action";
import moment from "moment";
import settings from "../../store/setting";
import "./style.scss";

const { Title, Text } = Typography;
const { confirm } = Modal;
const runningStatus = ["in queue", "starting...", "running"];
const { Search } = Input;
const { Option } = Select;

class SitesListComponent extends React.Component {
  state = {
    siteModalVisible: false,
    deleteModalVisible: false,
    scheduleModalVisible: false,
    selectedSite: {},
    sitesSelected: [],
    pagination: { current: 1 },
    sorter: {},
    busy: false,
    searchQuery: "",
    selectedTheme: "",
    filterCrashed: false,
    filterDead: false,
    filterWorking: "all",
    filterConfigured: "all",
    btnClicked: {},
  };

  columns = [
    {
      title: "Site Name",
      dataIndex: "siteName",
      sorter: true,
    },
    {
      title: "Theme",
      dataIndex: "themeName",
      sorter: true,
    },
    {
      title: "Url",
      dataIndex: "url",
      sorter: true,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (text) => {
        return new Date(text).toLocaleString();
      },
      sorter: true,
    },
    {
      title: "JS site?",
      dataIndex: "javascript",
      render: (v) => {
        return v ? "Yes" : "No";
      },
      sorter: true,
    },
    {
      title: "Action Steps (length)",
      dataIndex: "actionSteps",
      render: (steps) => {
        return steps && steps.length > 0 ? steps.length : "Not defined";
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, site) => {
        if (!text) text = "draft";
        if (site.crashed) text = "timed out";
        let content = text;
        content = toTitleCase(content);
        return <Tag color={statusColors[text]}>{content}</Tag>;
      },
      sorter: true,
    },
    {
      title: "Last Run",
      dataIndex: "lastRun",
      render: (text) => {
        return text ? new Date(text).toLocaleString() : "-";
      },
      sorter: true,
    },
    {
      title: "Last Run Duration",
      dataIndex: "lastRunFinish",
      render: (text, record) => {
        if (
          record["lastRun"] &&
          record["lastRunFinish"] &&
          record["status"] !== "running"
        ) {
          let result = moment
            .duration(
              moment(record["lastRunFinish"]).diff(moment(record["lastRun"]))
            )
            .humanize();
          return `in ${result}`;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Valid?",
      dataIndex: "isValid",
      render: (text) => {
        switch (text) {
          case 0:
            return <Tag>Not measured</Tag>;
          case 1:
            return <Tag color="green">Valid</Tag>;
          case -1:
            return <Tag color="red">Not valid</Tag>;
          default:
            return <Tag>Not meas ured</Tag>;
        }
      },
      sorter: true,
    },
    {
      title: "Dead?",
      dataIndex: "dead",
      render: (v) => {
        return v ? "Dead" : "-";
      },
      sorter: true,
    },
    {
      title: "Crashed?",
      dataIndex: "crashed",
      render: (v) => {
        return v ? "Crashed" : "-";
      },
      sorter: true,
    },
    {
      title: <span>Cars Count (valid/sold)</span>,
      dataIndex: "dataCount",
      render: (text, record) => {
        return (
          <span>
            {record["dataCount"]}
            &nbsp;&nbsp;
            <small>
              ({record["validCount"]} / {record["soldCount"]})
            </small>
          </span>
        );
      },
    },
    {
      title: "Timeout",
      dataIndex: "timeout",
      render: (v) => {
        return (v || this.props.timeout) + "s";
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      className: "action-wrapper",
      fixed: "right",
      render: (site) => {
        return (
          <div>
            {runningStatus.indexOf(site.status) !== -1 && (
              <Tooltip title="Stop scrapper">
                <Icon type="stop" onClick={() => this.stopSiteScrapper(site)} />
              </Tooltip>
            )}
            {site.status !== "draft" &&
              runningStatus.indexOf(site.status) === -1 &&
              (site.actionSteps.length > 0 ||
                (site.code && site.code.length > 0)) && (
                <Tooltip title="Run">
                  <Icon
                    type="caret-right"
                    style={{
                      color: this.state.btnClicked[site._id] ? "red" : "black",
                    }}
                    onClick={() => this.startSiteScrapper(site, true)}
                  />
                </Tooltip>
              )}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Tooltip title="Edit">
              <Icon type="edit" onClick={() => this.editSite(site)} />
            </Tooltip>
            <Tooltip title="View Detail">
              <Link to={`/sites/${site._id}`}>
                <Icon type="eye" />
              </Link>
            </Tooltip>
            <Tooltip title="Delete">
              <Icon type="delete" onClick={() => this.deleteSite(site)} />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  // rowSelection object indicates the need for row selection
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({
        sitesSelected: selectedRows,
      });
    },
  };

  componentDidMount() {
    if (!!this.props.pageFilters.pagination) {
      this.setState({ ...this.props.pageFilters });
    }
    this.props.getThemes();
    this.props.getTimeout();
    setTimeout(() => {
      this.handlePageChange();
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.apiProcessing && !nextProps.apiProcessing) {
      if (this.state.siteModalVisible)
        this.setState({
          siteModalVisible: false,
        });
      if (this.state.scheduleModalVisible)
        this.setState({
          scheduleModalVisible: false,
        });
    }
  }

  clearBtnClick = (site_id) => {
    this.setState({
      btnClicked: {
        ...this.state.btnClicked,
        [site_id]: true,
      },
    });
    setTimeout(() => {
      this.setState({
        btnClicked: {
          ...this.state.btnClicked,
          [site_id]: false,
        },
      });
    }, 30000);
  };

  editSchedule = (site) => {
    this.setState({
      selectedSite: site,
      scheduleModalVisible: true,
    });
  };

  onSiteSubmit = (site) => {
    let new_site = {
      ...this.state.selectedSite,
      ...site,
    };
    if (new_site._id) this.props.updateSite(new_site);
    else this.props.createSite(new_site);
  };

  editSite = (site) => {
    this.setState({
      selectedSite: site,
      siteModalVisible: true,
    });
  };

  siteScheduleUpdate = (schedule) => {
    this.props.updateSiteSchedule(this.state.selectedSite._id, schedule);
  };

  startSiteScrapper = (site, test) => {
    if (this.state.btnClicked[site._id]) return;

    this.props.startSiteScrapper(site._id, test);

    this.clearBtnClick(site._id);
  };

  stopSiteScrapper = (site) => {
    let self = this;
    confirm({
      title: "Do you really want to stop the scrapper?",
      content: "It may ",
      onOk() {
        self.props.stopSiteScrapper(site._id);
      },
      onCancel() {},
    });
  };

  createSite = () => {
    this.setState({
      selectedSite: {},
      siteModalVisible: true,
    });
  };

  deleteSite = (site) => {
    let description = "";
    let self = this;
    if (site.actionSteps && site.actionSteps.length > 0)
      description =
        "This sites includes scrapping steps already setup. Deleteing this site will result in removal of all actionsteps information and scrapped data!";
    else description = "";
    confirm({
      title: "Are you sure delete this site?",
      content: description,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        self.props.deleteSite(site);
      },
      onCancel() {},
    });
  };

  handlePageChange = (pagination, filter, sorter) => {
    console.log(sorter);
    let {
      searchQuery,
      selectedTheme,
      filterCrashed,
      filterDead,
      filterWorking,
      filterConfigured,
    } = this.state;
    if (!pagination) pagination = this.state.pagination;
    if (!sorter) sorter = this.state.sorter;
    let sort = {};
    if (sorter.field) sort[sorter.field] = sorter.order === "ascend" ? 1 : -1;
    this.props.getSites(
      (pagination.current - 1) * settings.pageSize,
      settings.pageSize,
      searchQuery,
      selectedTheme,
      sort,
      filterCrashed,
      filterDead,
      filterWorking,
      filterConfigured
    );
    this.setState({ pagination, sorter });
    this.props.setPageFilters({
      pagination,
      sorter,
      searchQuery,
      selectedTheme,
      filterCrashed,
      filterDead,
      filterWorking,
      filterConfigured,
    });
  };

  bulkActions = (action) => {
    let { sitesSelected } = this.state;
    let self = this;
    let timePerSite = 50;
    this.setState({
      busy: true,
    });
    new Promise((resolve) => {
      switch (action) {
        case "start":
          sitesSelected.map((s) => this.startSiteScrapper(s, true));
          resolve();
          break;
        case "stop":
          confirm({
            title: "Are you sure stop all selected sites?",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
              sitesSelected.map((s) => self.props.stopSiteScrapper(s._id));
              resolve();
            },
            onCancel() {},
          });
          resolve();
          break;
        case "delete":
          confirm({
            title: "Are you sure delete all selected sites?",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
              sitesSelected.map((s) => self.props.deleteSite(s));
              resolve();
            },
            onCancel() {},
          });
          break;
        default:
          break;
      }
    }).then(() => {
      setTimeout(() => {
        this.handlePageChange(this.state.pagination);
        this.setState({
          sitesSelected: [],
        });
        this.setState({
          busy: false,
        });
      }, this.state.sitesSelected.length * timePerSite);
    });
  };

  setWorkingFilter = (v) => {
    this.setState({
      filterWorking: v,
    });
    setTimeout(() => this.handlePageChange({ current: 1 }));
  };

  setConfiguredFilter = (v) => {
    this.setState({
      filterConfigured: v,
    });
    setTimeout(() => this.handlePageChange({ current: 1 }));
  };

  render() {
    let { sites, themes, apiProcessing, sitesCount, resultSitesCount } =
      this.props;
    let {
      sitesSelected,
      busy,
      pagination,
      filterCrashed,
      filterDead,
      filterConfigured,
      filterWorking,
    } = this.state;
    apiProcessing = apiProcessing || busy;
    const bulkMenu = (
      <Menu>
        <Menu.Item onClick={() => this.bulkActions("start")}>
          <Text>Start</Text>
        </Menu.Item>
        <Menu.Item onClick={() => this.bulkActions("stop")}>
          <Text>Stop</Text>
        </Menu.Item>
        <Menu.Item onClick={() => this.bulkActions("delete")}>
          <Text type="danger">Delete</Text>
        </Menu.Item>
      </Menu>
    );

    const filterWorkingMenu = (
      <Menu>
        <Menu.Item onClick={() => this.setWorkingFilter("all")}>
          <Text>All</Text>
        </Menu.Item>
        <Menu.Item onClick={() => this.setWorkingFilter("yes")}>
          <Text>Working</Text>
        </Menu.Item>
        <Menu.Item onClick={() => this.setWorkingFilter("no")}>
          <Text>Not Working</Text>
        </Menu.Item>
      </Menu>
    );

    const filterConfiguredMenu = (
      <Menu>
        <Menu.Item onClick={() => this.setConfiguredFilter("all")}>
          <Text>All</Text>
        </Menu.Item>
        <Menu.Item onClick={() => this.setConfiguredFilter("yes")}>
          <Text>Configured</Text>
        </Menu.Item>
        <Menu.Item onClick={() => this.setConfiguredFilter("no")}>
          <Text>Unconfigured</Text>
        </Menu.Item>
      </Menu>
    );

    return (
      <Row className="sites-listing">
        <Col>
          <div className="sites-topbar">
            <div>
              <Title level={3}>
                {" "}
                Sites
                <small>
                  <Text type="secondary">
                    &nbsp;
                    {sitesCount} / {resultSitesCount} sites
                  </Text>
                </small>
                <Icon
                  className="sites-reload"
                  type="reload"
                  spin={apiProcessing}
                  onClick={() => {
                    this.handlePageChange();
                  }}
                />
              </Title>
            </div>
            <div className="search-wrapper">
              <Search
                disabled={apiProcessing}
                className="query-input"
                placeholder="Search"
                enterButton
                value={this.state.searchQuery}
                onChange={(v) => this.setState({ searchQuery: v.target.value })}
                onSearch={(value) => {
                  this.setState({ searchQuery: value });
                  setTimeout(() => this.handlePageChange({ current: 1 }));
                }}
              />
              <Select
                disabled={apiProcessing}
                className="theme-selector"
                value={this.state.selectedTheme}
                onChange={(v) => {
                  this.setState({ selectedTheme: v });
                  setTimeout(() => this.handlePageChange({ current: 1 }));
                }}
              >
                <Option key="none" value={""}>
                  Select Theme
                </Option>
                <Option key="null" value={"null"}>
                  No Theme
                </Option>
                {themes.map((t) => (
                  <Option key={t._id} value={t._id}>
                    {t.themeName}
                  </Option>
                ))}
              </Select>
              {this.state.searchQuery || this.state.selectedTheme ? (
                <Button
                  disabled={apiProcessing}
                  type="secondary"
                  onClick={() => {
                    this.setState({
                      searchQuery: "",
                      selectedTheme: "",
                    });
                    setTimeout(() => this.handlePageChange({ current: 1 }));
                  }}
                >
                  Clear Filters
                </Button>
              ) : null}
              <Button
                disabled={apiProcessing}
                type="danger"
                ghost={!filterCrashed}
                onClick={() => {
                  this.setState({
                    filterCrashed: !filterCrashed,
                  });
                  setTimeout(() => this.handlePageChange({ current: 1 }));
                }}
              >
                Crashed
              </Button>
              <Button
                disabled={apiProcessing}
                type="danger"
                ghost={!filterDead}
                onClick={() => {
                  this.setState({
                    filterDead: !filterDead,
                  });
                  setTimeout(() => this.handlePageChange({ current: 1 }));
                }}
              >
                Dead
              </Button>
              <Dropdown overlay={filterWorkingMenu}>
                <Button>Working - {filterWorking}</Button>
              </Dropdown>
              <Dropdown overlay={filterConfiguredMenu}>
                <Button>Configured - {filterConfigured}</Button>
              </Dropdown>
            </div>
            <div>
              {sitesSelected.length > 0 && (
                <Dropdown overlay={bulkMenu}>
                  <Button>Bulk Actions</Button>
                </Dropdown>
              )}
              <Divider type="vertical" />
              <Button type="primary" onClick={this.createSite}>
                {" "}
                <Icon type="plus" /> Add New
              </Button>
            </div>
          </div>
          <Table
            rowKey="_id"
            pagination={{
              current: pagination.current || 1,
              total: resultSitesCount,
              pageSize: settings.pageSize,
            }}
            scroll={{ x: true }}
            onChange={this.handlePageChange}
            rowSelection={this.rowSelection}
            columns={this.columns}
            dataSource={sites}
          />
        </Col>

        <Modal
          title={this.state.selectedSite._id ? "Update Site" : "Create Site"}
          visible={this.state.siteModalVisible}
          onCancel={() => {
            this.setState({ siteModalVisible: false });
          }}
          footer={null}
          bodyStyle={{ paddingBottom: "1px" }}
        >
          <SiteForm
            key={this.state.siteModalVisible}
            onSubmit={this.onSiteSubmit}
            site={this.state.selectedSite}
          />
        </Modal>
      </Row>
    );
  }
}

export default connect(
  (state) => ({
    ...state.App,
    ...state.Sites,
    ...state.Themes,
    timeout: state.Summary.timeout,
  }),
  {
    ...siteActions,
    ...themeActions,
    ...summaryActions,
  }
)(SitesListComponent);
