import React from "react";
import { Route, withRouter, Switch } from "react-router";
import "./style.scss";
import ThemesListComponent from "../../views/themes";
import ThemeDetailComponent from "../../views/theme-detail";

const routes = [
  {
    path: "/",
    exact: true,
    component: <ThemesListComponent />,
    icon: "ordered-list",
  },
  {
    path: "/:themeId",
    component: <ThemeDetailComponent />,
    icon: "file",
  },
];

class ThemesRouter extends React.Component {
  render() {
    return (
      <Switch>
        {routes.map((item, idx) => {
          return (
            <Route
              key={idx}
              path={"/themes" + item.path}
              exact={item.exact}
              component={() => item.component}
            />
          );
        })}
      </Switch>
    );
  }
}

export default withRouter(ThemesRouter);
