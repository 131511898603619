export function toTitleCase(txt) {
  if (!txt) return "";
  return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
}

export function numberFormat(x) {
  if (!x) return "0";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const statusColors = {
  draft: "purple",
  pending: "geekblue",
  running: "cyan",
  error: "red",
  stopped: "gold",
  done: "green",
};
