import { all, takeLatest, put, call } from "redux-saga/effects";
import siteActions from "./action";
import appActions from "../app/action";
import { apiCall } from "../http";
import {
  getSitesUrl,
  getSingleSiteUrl,
  getDataUrl,
  deleteDataUrl,
  createSiteUrl,
  updateSiteUrl,
  updateScheduleUrl,
  updateActionStepUrl,
  startSiteScrapperUrl,
  stopSiteScrapperUrl,
  siteToggleJSUrl,
  siteToggleDeadUrl,
  clearCarUrl,
} from "../../utils/sitesUrls";
import { notification } from "antd";

export function* getSites({
  skip,
  limit,
  query = "",
  themeId = "",
  sort = {},
  filterCrashed,
  filterDead,
  filterWorking,
  filterConfigured,
}) {
  yield put({ type: appActions.API_CALL_START });
  try {
    const response = yield call(() =>
      apiCall(
        getSitesUrl(
          skip,
          limit,
          query,
          themeId,
          sort,
          filterCrashed,
          filterDead,
          filterWorking,
          filterConfigured
        )
      )
    );
    yield put({
      type: siteActions.SET_SITES,
      sites: response.sites,
      count: response.total,
      resultCount: response.resultCount,
    });
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export function* getSingleSite({ siteId, sites }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    let site = yield call(() => apiCall(getSingleSiteUrl(siteId)));
    site.actionSteps.map((step) => {
      delete step._id;
      return null;
    });
    sites.map((s, idx) => {
      if (s._id === siteId) sites[idx] = site;
      return null;
    });
    yield put({
      type: siteActions.SET_SITES,
      sites: sites,
    });
    yield put({
      type: siteActions.SET_ACTIVE_SITE,
      site: site,
    });
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export function* getData({ siteId, skip, limit }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    const response = yield call(() => apiCall(getDataUrl(siteId, skip, limit)));
    yield put({
      type: siteActions.SET_DATA,
      data: response,
    });
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export function* deleteData({ siteId }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    yield call(() => apiCall(deleteDataUrl(siteId), "DELETE"));
    yield put({
      type: siteActions.SET_DATA,
      data: [],
    });
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export function* clearCars() {
  yield put({ type: appActions.API_CALL_START });
  try {
    notification.info({
      message: "Clearing Cars",
      duration: 2,
    });
    yield call(() => apiCall(clearCarUrl(), "POST"));
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export function* createSite({ site, sites }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    let new_site = yield call(() => apiCall(createSiteUrl(), "POST", site));
    sites.unshift(new_site);
    yield put({
      type: siteActions.SET_SITES,
      sites: sites,
    });
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export function* updateSite({ site, sites }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    yield call(() => apiCall(updateSiteUrl(site._id), "PUT", site));
    sites.map((s, idx) => {
      if (s._id === site._id) sites[idx] = site;
      return null;
    });
    yield put({
      type: siteActions.SET_SITES,
      sites: sites,
    });
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export function* deleteSite({ site, sites }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    yield call(() => apiCall(updateSiteUrl(site._id), "DELETE", site));
    let s_idx = -1;
    sites.map((s, idx) => {
      if (s._id === site._id) s_idx = idx;
      return null;
    });
    sites.splice(s_idx, 1);
    yield put({
      type: siteActions.SET_SITES,
      sites: sites,
    });
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export function* updateActionStep({ siteId, steps, sites }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    yield call(() =>
      apiCall(updateActionStepUrl(siteId), "POST", {
        actionSteps: JSON.stringify(steps),
      })
    );
    let s = sites.filter((s) => s._id === siteId);
    s.actionSteps = steps;
    notification.success({
      message: "Action Steps saved.",
    });
    yield put({
      type: siteActions.SET_SITES,
      sites: sites,
    });
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export function* updateSchedule({ siteId, schedule, sites }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    yield call(() => apiCall(updateScheduleUrl(siteId), "POST", { schedule }));
    let s = sites.filter((s) => s._id === siteId)[0];
    s.schedule = schedule;
    yield put({
      type: siteActions.SET_SITES,
      sites: sites,
    });
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export function* startSiteScrapper({ siteId, isTest }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    notification.info({
      message: "Starting Scrapper",
    });
    yield call(() => apiCall(startSiteScrapperUrl(siteId, isTest), "POST"));
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export function* stopSiteScrapper({ siteId }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    notification.info({
      message: "Stopping Scrapper...",
    });
    yield call(() => apiCall(stopSiteScrapperUrl(siteId), "POST"));
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export function* toggleSiteJS({ site, sites }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    yield call(() => apiCall(siteToggleJSUrl(site._id), "POST"));
    sites.map((s, idx) => {
      if (s._id === site._id) sites[idx] = site;
      return null;
    });
    site.javascript = !site.javascript;
    yield put({
      type: siteActions.SET_SITES,
      sites: sites,
    });
    yield put({
      type: siteActions.SET_ACTIVE_SITE,
      site: site,
    });
    notification.info({
      message: "Toggled Javascript handle",
    });
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export function* toggleSiteDead({ site, sites }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    yield call(() => apiCall(siteToggleDeadUrl(site._id), "POST"));
    sites.map((s, idx) => {
      if (s._id === site._id) sites[idx] = site;
      return null;
    });
    site.dead = !site.dead;
    yield put({
      type: siteActions.SET_SITES,
      sites: sites,
    });
    yield put({
      type: siteActions.SET_ACTIVE_SITE,
      site: site,
    });
    notification.info({
      message: "Toggled Dead handle",
    });
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(siteActions.CLEAR_CARS, clearCars),
    yield takeLatest(siteActions.GET_SITES, getSites),
    yield takeLatest(siteActions.GET_SINGLE_SITE, getSingleSite),
    yield takeLatest(siteActions.GET_DATA, getData),
    yield takeLatest(siteActions.DELETE_DATA, deleteData),
    yield takeLatest(siteActions.CREATE_SITE, createSite),
    yield takeLatest(siteActions.UPDATE_SITE, updateSite),
    yield takeLatest(siteActions.DELETE_SITE, deleteSite),
    yield takeLatest(siteActions.UPDATE_SCHEDULE, updateSchedule),
    yield takeLatest(siteActions.UPDATE_ACTION_STEP, updateActionStep),
    yield takeLatest(siteActions.START_SITE_SCRAPPER, startSiteScrapper),
    yield takeLatest(siteActions.STOP_SITE_SCRAPPER, stopSiteScrapper),
    yield takeLatest(siteActions.TOGGLE_SITE_JS, toggleSiteJS),
    yield takeLatest(siteActions.TOGGLE_SITE_DEAD, toggleSiteDead),
  ]);
}
