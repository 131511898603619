import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import App from "./app/reducer";
import Auth from "./auth/reducer";
import Sites from "./sites/reducer";
import Themes from "./themes/reducer";
import Summary from "./summary/reducer";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    App,
    Auth,
    Sites,
    Themes,
    Summary,
  });
