import React from "react";
import {
  Icon,
  Layout,
  Typography,
  Divider,
  Input,
  Button,
  Col,
  Row,
  Switch,
} from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import summaryActions from "../../store/summary/action";
import siteActions from "../../store/sites/action";
import userActions from "../../store/auth/action";
import { numberFormat } from "../../utils/libs";
import { apiCall } from "../../store/http";
import "./style.scss";
import moment from "moment";

const { Content } = Layout;
const { Title } = Typography;

class Dashboard extends React.Component {
  state = {
    timeout: null,
  };

  componentDidMount() {
    this.props.getSummary();
    this.props.getUser();
  }

  startS3Saving = async () => {
    await apiCall("/s3/populate", "POST");
    this.props.getUser();
  };

  gotoSites = (crashed, dead, working, configured, selectedTheme = "") => {
    this.props.setPageFilters({
      pagination: { current: 1 },
      filterCrashed: crashed,
      filterDead: dead,
      filterWorking: working,
      filterConfigured: configured,
      selectedTheme,
    });
    this.props.history.push("/sites");
  };

  render() {
    const {
      site_avg_count,
      total_cars_count,
      total_sites_count,
      site_no_theme_count,
      total_themes,
      apiProcessing,
      sold_cars_count,
      instock_cars_count,
      configured,
      unconfigured,
      partial_configured,
      dead_sites_count,
      crashed_site_count,
      timeout,
      cycle_times,
      working_sites,
      not_working_sites,
      offS3,
      user,
    } = this.props;

    const cycle_duration = moment.duration(
      moment(cycle_times.lastFinish).diff(moment(cycle_times.firstStart))
    );

    const all_started = moment(user.allScrapperStarted).format(
      "YYYY/MM/DD HH:mm:ss"
    );

    return (
      <Layout>
        <Content className="dashboard">
          <Title level={3}>
            {" "}
            Dashboard
            <Icon
              className="summary-reload"
              type="reload"
              spin={apiProcessing}
              onClick={() => {
                this.props.getSummary();
              }}
            />
          </Title>
          <div className="summary-wrapper">
            <div className="summary-item">
              <label>Total Cars</label>
              <span>{numberFormat(total_cars_count)}</span>
            </div>
            <div className="summary-item">
              <label>Sold Cars</label>
              <span>{numberFormat(sold_cars_count)}</span>
            </div>
            <div className="summary-item">
              <label>In Stock cars</label>
              <span>{numberFormat(instock_cars_count)}</span>
            </div>
            <div className="summary-item">
              <label>Average cars count per site</label>
              <span>{numberFormat(site_avg_count)}</span>
            </div>
            <div
              className="summary-item hand-cursor"
              onClick={() => {
                this.gotoSites(false, false, "all", "all", "null");
              }}
            >
              <label>Sites without Theme</label>
              <span>{numberFormat(site_no_theme_count)}</span>
            </div>
            <div className="summary-item">
              <label>Total Themes</label>
              <span>{numberFormat(total_themes)}</span>
            </div>

            <div
              className="summary-item hand-cursor"
              onClick={() => {
                this.gotoSites(false, false, "all", "all");
              }}
            >
              <label>Total Sites</label>
              <span>{numberFormat(total_sites_count)}</span>
            </div>
            <div
              className="summary-item hand-cursor"
              onClick={() => {
                this.gotoSites(false, false, "all", "yes");
              }}
            >
              <label>Configured</label>
              <span>{numberFormat(configured)}</span>
            </div>
            <div
              className="summary-item hand-cursor"
              onClick={() => {
                this.gotoSites(false, false, "all", "no");
              }}
            >
              <label>Unconfigured</label>
              <span>{numberFormat(unconfigured)}</span>
            </div>
            {/* <div className='summary-item'>
              <label>Partial Configured</label>
              <span>{ numberFormat(partial_configured)}</span>
            </div> */}
            <div
              className="summary-item hand-cursor"
              onClick={() => {
                this.gotoSites(false, true, "all", "all");
              }}
            >
              <label>Dead Sites</label>
              <span>{numberFormat(dead_sites_count)}</span>
            </div>
            <div
              className="summary-item hand-cursor"
              onClick={() => {
                this.gotoSites(true, false, "all", "all");
              }}
            >
              <label>Crashed Sites</label>
              <span>{numberFormat(crashed_site_count)}</span>
            </div>

            <div className="summary-item">
              <label>All scrappers started.</label>
              <span>{all_started}</span>
            </div>

            <div className="summary-item">
              <label>Cycle Duration</label>
              <span>
                {cycle_duration.hours()}hrs&nbsp;
                {cycle_duration.minutes()}mins&nbsp;
              </span>
            </div>
            <div
              className="summary-item"
              onClick={() => {
                this.gotoSites(false, false, "yes", "all");
              }}
            >
              <label>Working Sites</label>
              <span>{numberFormat(working_sites)}</span>
            </div>
            <div
              className="summary-item"
              onClick={() => {
                this.gotoSites(false, false, "no", "all");
              }}
            >
              <label>Not Working Sites</label>
              <span>{numberFormat(not_working_sites)}</span>
            </div>
          </div>
          <Divider />
          <div>
            <Title level={3}>Global Settings</Title>
            <Row>
              <Col md={3}>
                <Title level={4}>Timeout</Title>
                <Input
                  type="number"
                  onChange={(e) => {
                    this.setState({
                      timeout: e.target.value,
                    });
                  }}
                  value={this.state.timeout || timeout}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    this.props.setTimeout(this.state.timeout);
                  }}
                >
                  Save
                </Button>
              </Col>
              <Col md={3}>
                <div>
                  <label>Run with S3</label>
                  &nbsp;&nbsp;&nbsp;
                  <Switch checked={!offS3} onChange={this.props.toggleOffS3} />
                </div>
              </Col>
              <Col md={3}>
                <label>Save Instock car images to S3</label>
                &nbsp;&nbsp;&nbsp;
                {this.props.user.s3Processing ? (
                  "Saving images to s3..."
                ) : (
                  <Button onClick={this.startS3Saving}>Go!</Button>
                )}
              </Col>
              <Col md={3}>
                <Button
                  type="danger"
                  onClick={() => {
                    if (window.confirm("Do you want to clear data of cars?")) {
                      this.props.clearCars();
                    }
                  }}
                >
                  Empty cars
                </Button>
              </Col>
            </Row>
            <Divider />
          </div>
        </Content>
      </Layout>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      ...state.App,
      ...state.Summary,
      user: state.Auth.user,
    }),
    {
      ...summaryActions,
      ...siteActions,
      ...userActions,
    }
  )(Dashboard)
);
