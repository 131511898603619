import React from "react";
import {
  Row,
  Col,
  Table,
  Alert,
  Icon,
  Divider,
  Typography,
  Tooltip,
} from "antd";
import ImageGallery from "react-image-gallery";
import settings from "../../store/setting";
import "./style.scss";

const { Title } = Typography;

class DataTableComponent extends React.Component {
  pagination = { current: 1 };

  columns = [
    {
      title: "make",
      dataIndex: "make",
    },
    {
      title: "model",
      dataIndex: "model",
    },
    {
      title: "price",
      dataIndex: "price",
    },
    {
      title: "mileage",
      dataIndex: "mileage",
    },
    {
      title: "color",
      dataIndex: "color",
    },
    {
      title: "transmission",
      dataIndex: "transmission",
    },
    {
      title: "year",
      dataIndex: "year",
    },
    {
      title: "doors",
      dataIndex: "doors",
    },
    {
      title: "body_type",
      dataIndex: "body_type",
    },
    {
      title: "dealer",
      dataIndex: "dealer",
    },
    {
      title: "engine_size",
      dataIndex: "engine_size",
    },
    {
      title: "fuel_type",
      dataIndex: "fuel_type",
    },
    {
      title: "images",
      dataIndex: "images",
      render: (text) => {
        return text.split("^^^").length;
      },
    },
    {
      title: "Valid",
      dataIndex: "valid",
      render: (text) => {
        return parseFloat(text).toFixed(2);
      },
    },
    {
      title: "Dealer Name",
      dataIndex: "dealer_name",
    },
    {
      title: "Dealer Phone",
      dataIndex: "dealer_phone",
    },
    {
      title: "Dealer Address",
      dataIndex: "dealer_address",
    },
    {
      title: "url",
      dataIndex: "url",
      render: (text) => {
        return (
          <Tooltip title={text} placement="left">
            <a href={text} target="_blank" rel="noopener noreferrer">
              link >
            </a>
          </Tooltip>
        );
      },
    },
  ];

  expandedRowRender = (record) => {
    let images = record.images.split("^^^").map((url) => {
      return {
        original: url,
        thumbnail: url,
        originalClass: "car-image",
      };
    });
    return (
      <div class="expanded-row">
        <Row>
          <Col lg={10}>
            <ImageGallery items={images} />
          </Col>
          <Col lg={14}>
            <Row>
              <Divider />
              <Col>
                <Row>
                  <Col lg={6} offset={2}>
                    <strong>Headline</strong>
                  </Col>
                  <Col lg={16}>{record["headline"]}</Col>
                </Row>
                <Row>
                  <Col lg={6} offset={2}>
                    <strong>make</strong>
                  </Col>
                  <Col lg={16}>{record["make"]}</Col>
                </Row>
                <Row>
                  <Col lg={6} offset={2}>
                    <strong>model</strong>
                  </Col>
                  <Col lg={16}>{record["model"]}</Col>
                </Row>
                <Row>
                  <Col lg={6} offset={2}>
                    <strong>price</strong>
                  </Col>
                  <Col lg={16}>{record["price"]}</Col>
                </Row>
                <Row>
                  <Col lg={6} offset={2}>
                    <strong>mileage</strong>
                  </Col>
                  <Col lg={16}>{record["mileage"]}</Col>
                </Row>
                <Row>
                  <Col lg={6} offset={2}>
                    <strong>color</strong>
                  </Col>
                  <Col lg={16}>{record["color"]}</Col>
                </Row>
                <Row>
                  <Col lg={6} offset={2}>
                    <strong>transmission</strong>
                  </Col>
                  <Col lg={16}>{record["transmission"]}</Col>
                </Row>
                <Row>
                  <Col lg={6} offset={2}>
                    <strong>year</strong>
                  </Col>
                  <Col lg={16}>{record["year"]}</Col>
                </Row>
                <Row>
                  <Col lg={6} offset={2}>
                    <strong>doors</strong>
                  </Col>
                  <Col lg={16}>{record["doors"]}</Col>
                </Row>
                <Row>
                  <Col lg={6} offset={2}>
                    <strong>body_type</strong>
                  </Col>
                  <Col lg={16}>{record["body_type"]}</Col>
                </Row>
                <Row>
                  <Col lg={6} offset={2}>
                    <strong>dealer</strong>
                  </Col>
                  <Col lg={16}>{record["dealer"]}</Col>
                </Row>
                <Row>
                  <Col lg={6} offset={2}>
                    <strong>url</strong>
                  </Col>
                  <Col lg={16}>
                    <a
                      href={record["url"]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {record["url"]}
                    </a>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} offset={2}>
                    <strong>engine_size</strong>
                  </Col>
                  <Col lg={16}>{record["engine_size"]}</Col>
                </Row>
                <Row>
                  <Col lg={6} offset={2}>
                    <strong>fuel_type</strong>
                  </Col>
                  <Col lg={16}>{record["fuel_type"]}</Col>
                </Row>
                <Row>
                  <Col lg={6} offset={2}>
                    <strong>dealer_name</strong>
                  </Col>
                  <Col lg={16}>{record["dealer_name"]}</Col>
                </Row>
                <Row>
                  <Col lg={6} offset={2}>
                    <strong>dealer_phone</strong>
                  </Col>
                  <Col lg={16}>{record["dealer_phone"]}</Col>
                </Row>
                <Row>
                  <Col lg={6} offset={2}>
                    <strong>dealer_address</strong>
                  </Col>
                  <Col lg={16}>{record["dealer_address"]}</Col>
                </Row>
                <Row>
                  <Col lg={6} offset={2}>
                    <strong>vehicle_description</strong>
                  </Col>
                  <Col lg={16}>{record["vehicle_description"]}</Col>
                </Row>
                <Divider />
                <Row>
                  <Col lg={6} offset={2}>
                    <strong>Inserted At</strong>
                  </Col>
                  <Col lg={16}>{record["updatedAt"]}</Col>
                </Row>
                <Row>
                  <Col lg={6} offset={2}>
                    <strong>Updated At</strong>
                  </Col>
                  <Col lg={16}>{record["createdAt"]}</Col>
                </Row>
                <Row>
                  <Col lg={6} offset={2}>
                    <strong>Status</strong>
                  </Col>
                  <Col lg={16}>{record["status"]}</Col>
                </Row>
                <Row>
                  <Col lg={6} offset={2}>
                    <strong>Sold Date</strong>
                  </Col>
                  <Col lg={16}>{record["soldAt"]}</Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col></Col>
        </Row>
      </div>
    );
  };

  handePageChange = (pagination) => {
    this.pagination = pagination;

    if (this.props.handePageChange) this.props.handePageChange(pagination);
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.isRunning !== nextProps.isRunning)
      this.handePageChange(this.pagination);
  }

  render() {
    let { data, isRunning, apiProcessing } = this.props;
    return (
      <Row className="car-data-table">
        <Col>
          <div className="caption-row">
            <Title level={3}>
              Data Table
              <Icon
                type="reload"
                className="reload-data"
                onClick={() => this.handePageChange(this.pagination)}
                spin={apiProcessing}
              />
            </Title>
            <small>Total {data.total} cars</small>
          </div>
          {isRunning && (
            <Alert
              message={<label>Site scrapper is currently running!</label>}
              type="warning"
              showIcon
            />
          )}
          <Table
            rowKey="_id"
            columns={this.columns}
            dataSource={data.data}
            onChange={this.handePageChange}
            pagination={{
              total: data.total,
              pageSize: settings.pageSize,
            }}
            scroll={{ x: true }}
            expandedRowRender={this.expandedRowRender}
          />
        </Col>
      </Row>
    );
  }
}

export default DataTableComponent;
