import React from "react";
import { notification } from "antd";

notification.config({
  placement: "bottomLeft",
});

export function toast(
  type = "info",
  message = "",
  description = "",
  duration = 4.5,
  callback = null
) {
  const config = {
    message: message,
    description: <div dangerouslySetInnerHTML={{ __html: description }}></div>,
    duration: duration,
    onClick: () => {
      if (typeof callback === "function") {
        callback();
      }
    },
  };
  switch (type) {
    case "info":
      notification.info(config);
      break;
    case "success":
      notification.success(config);
      break;
    case "error":
      notification.error(config);
      break;
    case "warning":
      notification.warning(config);
      break;
    case "warn":
      notification.warn(config);
      break;
    default:
      notification.open(config);
  }
}
