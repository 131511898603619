import React from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter, Redirect } from "react-router";
import { ConnectedRouter } from "connected-react-router";
import AuthComponent from "../views/auth";
import SitesRouter from "./sites";
import ThemesRouter from "./themes";
import Dashboard from "../views/dashboard";
import SitesSocketComponent from "../utils/sitesSocketComponent";
import TopBar from "../components/topbar";
import SideBar from "../components/sidebar";
import { Layout } from "antd";
const { Content, Footer } = Layout;

const RestrictedRoute = withRouter(({ component, isLoggedIn, ...rest }) => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <SideBar />
      <Layout className="main-layout">
        <TopBar />
        <Content className="main-container">
          <div className="sites-content">
            <Route
              {...rest}
              render={(props) => {
                return isLoggedIn ? (
                  component
                ) : (
                  <Redirect
                    to={{
                      pathname: "/auth/signin",
                      state: { from: props.location },
                    }}
                  />
                );
              }}
            />
          </div>
        </Content>
        <Footer style={{ textAlign: "center", background: "#fff" }}>
          Carmart ©2019 Created by @Carmart
        </Footer>
      </Layout>

      <SitesSocketComponent />
    </Layout>
  );
});

function AppRouter({ token, history }) {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/auth/:path" exact component={() => <AuthComponent />} />
        <RestrictedRoute
          path="/sites"
          isLoggedIn={!!token}
          component={<SitesRouter />}
        />
        <RestrictedRoute
          path="/themes"
          isLoggedIn={!!token}
          component={<ThemesRouter />}
        />
        <RestrictedRoute
          path="/dashboard"
          isLoggedIn={!!token}
          component={<Dashboard />}
        />
        <Route path="" exact component={() => <Redirect to="/themes" />} />
      </Switch>
    </ConnectedRouter>
  );
}

export default connect((state) => ({
  ...state.Auth,
}))(AppRouter);
