import React from "react";
import { all, takeLatest, put, call, delay } from "redux-saga/effects";
import { push } from "connected-react-router";
import actions from "./action";
import appActions from "../app/action";
import setting from "../setting";
import { notification } from "antd";
import { signInUrl, signUpUrl, singleUserUrl } from "../../utils/authUrls";

export function* signin({ username, password }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    let res_status;
    const response = yield call(() =>
      fetch(setting.apiUrl + signInUrl(), {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
      }).then((res) => {
        res_status = res.status;
        if (res.status !== 200) return res.text();
        return res.json();
      })
    );
    console.log(response.token);
    if (res_status === 200) {
      notification.success({
        message: "Login Success",
      });
      yield put({
        type: actions.SIGNIN_SUCCESS,
        token: response.token,
        id: response.id,
      });
      yield delay(1000);
      yield put(push("/sites/"));
    } else {
      throw response;
    }
  } catch (e) {
    notification.error({
      message: "Error ",
      description: <div dangerouslySetInnerHTML={{ __html: e }} />,
    });
    yield put({
      type: actions.AUTH_ERROR,
      message: "Username or Password incorrect",
      rawError: e,
    });
  }
  yield put({ type: appActions.API_CALL_END });
}

export function* signup({ username, password }) {
  yield put({ type: actions.START_PROCESS });
  try {
    let res_status;
    const response = yield call(() =>
      fetch(setting.apiUrl + signUpUrl(), {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
      }).then((res) => {
        res_status = res.status;
        if (res.status !== 200) return res.text();
        return res.json();
      })
    );
    if (res_status === 200) {
      yield put(push("/sites"));
    } else {
      throw response;
    }
  } catch (e) {
    notification.error({
      message: "Error ",
      description: <div dangerouslySetInnerHTML={{ __html: e }} />,
    });
    yield put({
      type: actions.AUTH_ERROR,
      message: "Signup failed",
    });
  }
  yield put({ type: appActions.API_CALL_END });
}

export function* getUser() {
  const userId = window.localStorage.getItem("user-id");
  const res = yield call(() =>
    fetch(setting.apiUrl + singleUserUrl(userId)).then((res) => res.json())
  );
  yield put({
    type: actions.SET_USER,
    user: res,
  });
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(actions.SIGNIN, signin),
    yield takeLatest(actions.SIGNUP, signup),
    yield takeLatest(actions.GET_USER, getUser),
  ]);
}
