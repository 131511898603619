import React from "react";
import socketIOClient from "socket.io-client";
import settings from "../store/setting";
import siteActions from "../store/sites/action";
import { connect } from "react-redux";
import { toast } from "./toast";

class SitesSocketComponent extends React.Component {
  sitesSockets = {};
  globalSocket = null;
  state = {
    signals: [],
    total: 0,
    opened: false,
  };

  componentWillReceiveProps(nextProps) {
    // this.processProps(nextProps)
  }

  componentWillUnmount() {
    for (let sock_id in this.sitesSockets) {
      this.sitesSockets[sock_id].close();
      delete this.sitesSockets[sock_id];
    }
    this.globalSocket.close();
    delete this.globalSocket;
  }

  componentDidMount() {
    if (!this.globalSocket) {
      this.globalSocket = socketIOClient(settings.sockUrl);
      this.globalSocket.on("connect", () => {
        this.globalSocket.on("data", (data) => {
          let { showNotification } = this.props;
          let raw_data = JSON.parse(data);
          const last_message =
            this.state.signals[this.state.signals.length - 1];
          if (JSON.stringify(last_message) === JSON.stringify(raw_data)) {
            return;
          }
          this.setState({
            signals: this.state.signals
              .slice(this.state.signals.length - 40, 40)
              .concat(raw_data),
            total: this.state.total + 1,
          });
          document.getElementById("console-toggle").style.color = "red";
          if (raw_data.message) {
            let allSites = [...this.props.sites, this.props.activeSite];
            let allSiteIds = allSites.map((s) => `${s._id}`);
            if (allSiteIds.indexOf(raw_data.siteId) !== -1)
              this.props.reloadSingleSite(raw_data.siteId);
            if (showNotification) {
              switch (raw_data.message) {
                case "started":
                  toast(
                    "info",
                    "Scrapper Started",
                    `scrapper <strong>${raw_data.siteName}</strong> started`
                  );
                  break;
                case "finished":
                  toast(
                    "success",
                    "Scrapper Finished",
                    `scrapper <strong>${raw_data.siteName}</strong> finished`
                  );
                  break;
                case "stopped":
                  toast(
                    "error",
                    "Scrapper Manually stopped",
                    `scrapper <strong>${raw_data.siteName}</strong> stopped`
                  );
                  break;
                default:
                  break;
              }
            }
          }
        });
      });
    }
  }

  componentDidUpdate() {
    setTimeout(() => {
      if (document.getElementById("console-toggle"))
        document.getElementById("console-toggle").style.color = "black";
    }, 200);
  }

  render() {
    let { opened } = this.state;
    let signals = [...this.state.signals];
    signals.reverse();
    return (
      <div
        style={{
          position: "fixed",
          maxWidth: "400px",
          maxHeight: "400px",
          minWidth: "400px",
          minHeight: "400px",
          bottom: opened ? "0" : "-325px",
          right: "10px",
          overflow: "hidden",
          padding: "10px",
          transition: ".3s",
          pointerEvents: "none",
          opacity: "0.6",
        }}
      >
        <div
          style={{
            display: "inline-block",
            width: "80px",
            textAlign: "center",
            borderTop: "1px solid #777",
            borderLeft: "1px solid #777",
            borderRight: "1px solid #777",
            cursor: "pointer",
            borderRadius: "5px 5px 0 0",
            position: "absolute",
            right: "20px",
            fontWeight: "bold",
            bottom: "calc(80% - 1px)",
            backgroundColor: "white",
            transition: ".2s",
            padding: "7px",
            zIndex: "1",
            pointerEvents: "all",
          }}
          id="console-toggle"
          onClick={() => {
            this.setState({ opened: !this.state.opened });
          }}
        >
          {this.state.total}
        </div>
        <ul
          style={{
            listStyleType: "none",
            left: "10px",
            right: "10px",
            maxHeight: "80%",
            position: "absolute",
            top: "20%",
            bottom: "10px",
            padding: "10px",
            margin: "0",
            overflow: "hidden",
            border: "1px solid #777",
            borderRadius: "10px",
            backgroundColor: "white",
            pointerEvents: "all",
          }}
        >
          {signals.map((s, idx) => {
            return (
              <li
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipse",
                }}
                key={idx}
              >
                {s.message || "data"} &nbsp;
                <strong>{!s.message && s.headline}</strong>
                {s.siteName} : {s.siteId}
                {JSON.stringify(s)}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.App,
    ...state.Sites,
  }),
  {
    ...siteActions,
  }
)(SitesSocketComponent);
