import { all } from "redux-saga/effects";

import authSaga from "./auth/saga";
import siteSaga from "./sites/saga";
import themeSaga from "./themes/saga";
import summarySaga from "./summary/saga";

export default function* rootSaga(getState) {
  yield all([authSaga(), siteSaga(), themeSaga(), summarySaga()]);
}
