import React from "react";
import { Form, Input, Button, Spin } from "antd";
import { connect } from "react-redux";

class SiteForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.props.onSubmit) this.props.onSubmit(values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { apiProcessing, site, timeout } = this.props;
    return (
      <Form onSubmit={this.handleSubmit} className="site-form">
        <Form.Item label="Site Name">
          {getFieldDecorator("siteName", {
            rules: [{ required: true, message: "Please input name of site!" }],
            initialValue: site.siteName,
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Url">
          {getFieldDecorator("url", {
            rules: [{ required: true, message: "Please input site url!" }],
            initialValue: site.url,
          })(<Input />)}
        </Form.Item>
        <Form.Item label="timeout">
          {getFieldDecorator("timeout", {
            rules: [
              { required: true, message: "Please input timeout per car!" },
            ],
            initialValue: site.timeout || timeout,
          })(<Input />)}
        </Form.Item>
        <Form.Item>
          <Spin tip="Loading..." spinning={apiProcessing}>
            <Button
              type="primary"
              htmlType="submit"
              className="form-button"
              disabled={apiProcessing}
            >
              {site._id ? "Update" : "Create"}
            </Button>
          </Spin>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedSiteForm = Form.create({
  name: "site_form",
})(SiteForm);

export default connect(
  (state) => ({
    ...state.App,
    timeout: state.Summary.timeout,
  }),
  {}
)(WrappedSiteForm);
