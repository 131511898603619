import React from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Tooltip,
  Divider,
  Switch,
  Icon,
  Tag,
  Typography,
  Modal,
} from "antd";
import { toTitleCase, statusColors } from "../../utils/libs";
import { withRouter } from "react-router-dom";
import siteActions from "../../store/sites/action";
import moment from "moment";
import DataTableComponent from "../../components/data-table";
import ActionStepsEditor from "../../components/actionstep-editor";
import settings from "../../store/setting";
import "./style.scss";

const { Title, Text } = Typography;
const { confirm } = Modal;

class SiteDetailComponent extends React.Component {
  siteId = null;

  state = {
    configure: false,
    siteId: null,
    btnClicked: false,
  };

  componentDidMount() {
    this.siteId = this.props.match.params.siteId;
    this.setState({
      siteId: this.siteId,
    });
    this.props.reloadSingleSite(this.siteId);
    this.props.getData(this.siteId);
  }

  clearBtnClick = () => {
    this.setState({
      btnClicked: true,
    });
    setTimeout(() => {
      this.setState({
        btnClicked: false,
      });
    }, 3000);
  };

  componentWillReceiveProps(nextProps) {
    if (
      this.props.activeSite.status === "running" &&
      nextProps.activeSite.status !== "running"
    )
      this.props.getData(this.siteId);
  }

  startSiteScrapper = (site, isTest) => {
    if (this.state.btnClicked) return;
    this.props.startSiteScrapper(site._id, isTest);
    this.clearBtnClick();
  };

  stopSiteScrapper = (site) => {
    this.props.stopSiteScrapper(site._id);
  };

  saveThemeParams = (themeParams) => {
    let { activeSite } = this.props;
    this.props.updateSite({
      ...activeSite,
      themeParams: themeParams,
    });
  };

  handePageChange = (pagination) => {
    this.props.getData(
      this.siteId,
      (pagination.current - 1) * settings.pageSize,
      settings.pageSize
    );
  };

  saveSteps = (steps) => {
    this.props.updateActionStep(this.siteId, steps);
  };

  saveCode = (code) => {
    let { activeSite } = this.props;
    this.props.updateSite({
      ...activeSite,
      code: code,
    });
  };

  toggleJS = () => {
    this.props.toggleSiteJS(this.props.activeSite);
  };

  removeAllData = () => {
    let self = this;
    confirm({
      title:
        "This action will permantly remove all cars registered under the site",
      onOk() {
        self.props.deleteData(self.state.siteId);
      },
      onCancel() {},
    });
  };

  render() {
    let site = this.props.activeSite;
    let { siteId } = this.state;
    if (!site || !site._id) {
      return null;
    }
    if (site._id !== siteId) return null;
    let { data } = this.props;

    let duration = "";
    if (
      site["lastRun"] &&
      site["lastRunFinish"] &&
      site["status"] !== "running"
    ) {
      let st = moment(site["lastRun"]);
      let ed = moment(site["lastRunFinish"]);
      let d = moment.duration(ed.diff(st)).asSeconds();
      duration = `in ${d.toFixed(2)} seconds`;
    } else {
      duration = "-";
    }
    let valid = "";
    switch (site.isValid) {
      case 0:
        valid = <Tag>Not measured</Tag>;
        break;
      case 1:
        valid = <Tag color="green">Valid</Tag>;
        break;
      case -1:
        valid = <Tag color="red">Not valid</Tag>;
        break;
      default:
        valid = <Tag>Not measured</Tag>;
    }
    // let deadTag = <Tag>Dead</Tag>
    let status = site.status;
    if (site.crashed) status = "timed out";
    return (
      <Row className="site-detail" key={siteId}>
        <Col>
          <Row type="flex" justify="space-between">
            <Col lg={5}>
              <div>
                <Title level={2} style={{ margin: 0 }} className="site-name">
                  <span>{site.siteName}&nbsp;&nbsp;</span>
                  <small>
                    <Text>(Site)</Text>
                  </small>
                </Title>
              </div>
            </Col>
            <Col lg={3}>
              <label>Url</label>
              <div className="site-url">{site.url}</div>
            </Col>
            <Col>
              <label>Created At</label>
              <div>{new Date(site.createdAt).toLocaleString()}</div>
            </Col>
            <Col>
              <label>Status</label>
              <div>
                <Tag color={statusColors[status]}>{toTitleCase(status)}</Tag>
              </div>
            </Col>
            <Col>
              <label>Last Run</label>
              <div>{new Date(site.lastRun).toLocaleString()}</div>
            </Col>
            <Col>
              <label>Last Run Duration</label>
              <div>{duration}</div>
            </Col>
            <Col>
              <label>JS Site</label>
              <div>{site.javascript ? "Yes" : "No"}</div>
            </Col>
            <Col>
              <label>Valid</label>
              <div>{valid}</div>
            </Col>
            <Col>
              <label>Expected Total Cars</label>
              <div>{site.totalExpected}</div>
            </Col>
            <Col className="actions-wrapper">
              <div className="js-toggle">
                <span> Dead </span>
                <div>
                  <Switch
                    style={{ backgroundColor: site.dead ? "#000" : "#ddd" }}
                    disabled={site.status === "running"}
                    checked={site.dead}
                    onChange={() => {
                      this.props.toggleSiteDead(this.props.activeSite);
                    }}
                  />
                </div>
              </div>
              {site.status === "running" && (
                <Tooltip title="Running">
                  <Icon
                    type="stop"
                    onClick={() => this.stopSiteScrapper(site)}
                  />
                </Tooltip>
              )}
              {site.status !== "draft" &&
                site.status !== "running" &&
                (site.actionSteps.length > 0 ||
                  (site.code && site.code.length > 0)) && [
                  <Tooltip title="Run" key="deeprun">
                    <Icon
                      type="caret-right"
                      style={{ color: this.state.btnClicked ? "red" : "black" }}
                      onClick={() => this.startSiteScrapper(site, true)}
                    />
                  </Tooltip>,
                  <Tooltip title="Remove All Sites" key="remove-all">
                    <Icon type="delete" onClick={this.removeAllData} />
                  </Tooltip>,
                  <Tooltip title="Test Run" key="testrun">
                    <Icon
                      type="play-circle"
                      onClick={() => this.startSiteScrapper(site)}
                    />
                  </Tooltip>,
                ]}
              <div
                className={`configure-wrapper ${
                  this.state.configure ? "active" : ""
                }`}
                onClick={() => {
                  this.setState({
                    configure: !this.state.configure,
                  });
                  this.props.reloadSingleSite(this.siteId);
                }}
              >
                <Tooltip title="Configure">
                  <Icon type="setting" theme="filled" />
                </Tooltip>
              </div>
            </Col>
          </Row>
          <Divider style={{ margin: "18px 0" }} />
          <Row>
            <Col>
              {this.state.configure ? (
                <ActionStepsEditor
                  key={site._id}
                  isRunning={site.status === "running"}
                  actionSteps={site.actionSteps}
                  isJS={site.javascript}
                  code={site.code}
                  fromTheme={site.themeName}
                  themeParams={site.themeParams}
                  jsToggle={this.toggleJS}
                  saveThemeParams={this.saveThemeParams}
                  saveSteps={this.saveSteps}
                  saveCode={this.saveCode}
                />
              ) : (
                <DataTableComponent
                  key={site._id}
                  apiProcessing={this.props.apiProcessing}
                  isRunning={site.status === "running"}
                  data={data}
                  handePageChange={this.handePageChange}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      ...state.App,
      ...state.Sites,
    }),
    {
      ...siteActions,
    }
  )(SiteDetailComponent)
);
