import settings from "../setting";
import { Modal } from "antd";

const { confirm } = Modal;

const actions = {
  GET_THEMES: "GET_THEMES",
  GET_SINGLE_THEME: "GET_SINGLE_THEME",
  SET_THEMES: "SET_THEMES",
  CREATE_THEME: "CREATE_THEME",
  UPDATE_THEME: "UPDATE_THEME",
  DELETE_THEME: "DELETE_THEME",
  RUN_THEME: "RUN_THEME",
  STOP_THEME: "STOP_THEME",
  UPDATE_ACTION_STEP: "THEME_UPDATE_ACTION_STEP",
  GET_DATA: "THEME_GET_DATA",
  SET_DATA: "THEME_SET_DATA",
  SET_ACTIVE_THEME: "SET_ACTIVE_THEME",
  START_THEME_SCRAPPER: "THEME_START_THEME_SCRAPPER",
  STOP_THEME_SCRAPPER: "THEME_STOP_THEME_SCRAPPER",
  REMOVE_ALL_SITES: "REMOVE_ALL_SITES",
  TOGGLE_THEME_JS: "TOGGLE_THEME_JS",

  getThemes:
    (skip = 0, limit = settings.pageSize) =>
    (dispatch) => {
      dispatch({ type: actions.GET_THEMES, skip, limit });
    },

  createTheme: (theme) => (dispatch, getState) => {
    let themes = [...getState().Themes.themes];
    dispatch({ type: actions.CREATE_THEME, theme, themes });
  },

  updateTheme: (theme) => (dispatch, getState) => {
    let themes = [...getState().Themes.themes];
    dispatch({ type: actions.UPDATE_THEME, theme, themes });
  },

  toggleThemeJS: (theme) => (dispatch, getState) => {
    let themes = [...getState().Themes.themes];
    dispatch({ type: actions.TOGGLE_THEME_JS, theme, themes });
  },

  deleteTheme: (theme) => (dispatch, getState) => {
    let themes = [...getState().Themes.themes];
    dispatch({ type: actions.DELETE_THEME, theme, themes });
  },

  runTheme: (themeId) => (dispatch) => {
    dispatch({ type: actions.RUN_THEME, themeId });
  },

  stopTheme: (themeId) => (dispatch) => {
    dispatch({ type: actions.STOP_THEME, themeId });
  },

  setActiveTheme: (theme) => (dispatch) => {
    dispatch({ type: actions.SET_ACTIVE_THEME, theme });
  },

  getData:
    (themeId, skip = 0, limit = settings.pageSize) =>
    (dispatch) => {
      dispatch({ type: actions.GET_DATA, themeId, skip, limit });
    },

  updateActionStep: (themeId, steps) => (dispatch, getState) => {
    let themes = [...getState().Themes.themes];
    dispatch({ type: actions.UPDATE_ACTION_STEP, themeId, steps, themes });
  },

  startThemeScrapper: (themeId, isTest) => (dispatch) => {
    dispatch({ type: actions.START_THEME_SCRAPPER, themeId, isTest });
  },

  stopThemeScrapper: (themeId) => (dispatch) => {
    confirm({
      title: "Do you really want to stop All scrappers on this theme?",
      content: "It may ",
      onOk() {
        dispatch({ type: actions.STOP_THEME_SCRAPPER, themeId });
      },
      onCancel() {},
    });
  },

  reloadSingleTheme: (themeId) => (dispatch, getState) => {
    let themes = [...getState().Themes.themes];
    dispatch({ type: actions.GET_SINGLE_THEME, themeId, themes });
  },

  removeAllSites: (themeId) => (dispatch, getState) => {
    let themes = [...getState().Themes.themes];
    dispatch({ type: actions.REMOVE_ALL_SITES, themeId, themes });
  },
};

export default actions;
