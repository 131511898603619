import actions from "./action";

const initialState = {
  sites: [],
  data: [],
  site: {},
  activeSite: {},
  sitesCount: 0,
  resultSitesCount: 0,
  pageFilters: {},
};

export default function sitesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_SITES:
      return {
        ...state,
        sites: action.sites,
        sitesCount:
          typeof action.count === "number" ? action.count : state.sitesCount,
        resultSitesCount:
          typeof action.resultCount === "number"
            ? action.resultCount
            : state.resultSitesCount,
      };
    case actions.SET_DATA:
      return {
        ...state,
        data: action.data,
      };
    case actions.SET_ACTIVE_SITE:
      return {
        ...state,
        activeSite: action.site,
      };
    case actions.SET_PAGE_FILTERS:
      return {
        ...state,
        pageFilters: action.filters,
      };
    default:
      return state;
  }
}
