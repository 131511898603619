import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Layout, Menu, Icon } from "antd";
import logo from "../../assets/logo-light.png";
import "./style.scss";

const { Sider } = Layout;

class SideBar extends React.Component {
  state = {
    collapsed: false,
  };

  onCollapse = (collapsed) => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  render() {
    const currentRoute = this.props.location.pathname.split("/")[1];
    return (
      <Sider
        collapsible
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse}
        className="sidebar"
      >
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Carmart" />
          </Link>
        </div>
        <Menu theme="dark" mode="inline" selectedKeys={[currentRoute]}>
          <Menu.Item key="dashboard">
            <Link to="/dashboard">
              <Icon type="pie-chart" />
              <span>Dashboard</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="themes">
            <Link to="/themes/">
              <Icon type="pie-chart" />
              <span>themes</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="sites">
            <Link to="/sites/">
              <Icon type="pie-chart" />
              <span>Sites</span>
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }
}

export default withRouter(SideBar);
