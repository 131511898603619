import React from "react";
import { Provider } from "react-redux";
import { store, history } from "./store";
import "./App.css";
import PublicRoutes from "./router";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'

function App() {
  return (
    <Provider store={store}>
      <PublicRoutes history={history} />
    </Provider>
  );
}

export default App;
