import React from "react";
import { connect } from "react-redux";
import { Row, Col, Tooltip, Divider, Modal, Icon, Typography } from "antd";
import { withRouter } from "react-router-dom";
import themeActions from "../../store/themes/action";
import DataTableComponent from "../../components/data-table";
import ActionStepsEditor from "../../components/actionstep-editor";
import settings from "../../store/setting";
import "./style.scss";

const { Title, Text } = Typography;
const { confirm } = Modal;

class ThemeDetailComponent extends React.Component {
  themeId = null;

  state = {
    configure: true,
  };

  componentDidMount() {
    this.themeId = this.props.match.params.themeId;
    this.props.reloadSingleTheme(this.themeId);
    this.props.getData(this.themeId);
  }

  componentWillReceiveProps(nextProps) {}

  startThemeScrapper = (theme, isTest) => {
    this.props.startThemeScrapper(theme._id, isTest);
  };

  stopThemeScrapper = (theme) => {
    this.props.stopThemeScrapper(theme._id);
  };

  handePageChange = (pagination) => {
    this.props.getData(
      this.themeId,
      (pagination.current - 1) * settings.pageSize,
      settings.pageSize
    );
  };

  removeAllSites = () => {
    let self = this;
    confirm({
      title:
        "This action will permantly remove all sites registered under the theme and its data",
      onOk() {
        self.props.removeAllSites(self.themeId);
      },
      onCancel() {},
    });
  };

  saveSteps = (steps) => {
    this.props.updateActionStep(this.themeId, steps);
  };

  toggleJS = () => {
    this.props.toggleThemeJS(this.props.activeTheme);
  };

  render() {
    let theme = this.props.activeTheme;
    let { data } = this.props;
    if (!theme || !theme._id) {
      return <div></div>;
    }

    return (
      <Row className="theme-detail" key={theme._id}>
        <Col>
          <Row type="flex" justify="space-between">
            <Col>
              <div>
                <Title level={2} style={{ margin: 0 }}>
                  {theme.themeName}&nbsp;&nbsp;
                  <small>
                    <Text>(Theme)</Text>
                  </small>
                </Title>
              </div>
            </Col>
            <Col>
              <label>Total Sites</label>
              <div>{theme.siteSummary.totalCount}</div>
            </Col>
            <Col>
              <label>Sites running</label>
              <div>{theme.siteSummary.runningCount}</div>
            </Col>
            <Col>
              <label>Sites queued</label>
              <div>{theme.siteSummary.queueCount}</div>
            </Col>
            <Col>
              <label>Total Cars</label>
              <div>{theme.dataCount}</div>
            </Col>
            <Col>
              <label>Created At</label>
              <div>{new Date(theme.createdAt).toLocaleString()}</div>
            </Col>
            <Col className="actions-wrapper">
              <Tooltip title="Run" key="deeprun">
                <Icon
                  type="caret-right"
                  onClick={() => this.startThemeScrapper(theme)}
                />
              </Tooltip>
              <Tooltip title="Test Run" key="testrun">
                <Icon
                  type="play-circle"
                  onClick={() => this.startThemeScrapper(theme, true)}
                />
              </Tooltip>
              <Tooltip title="Remove All Sites" key="remove-all">
                <Icon type="delete" onClick={this.removeAllSites} />
              </Tooltip>
              <Tooltip title="Stop All Scrappers" key="stop-all">
                <Icon
                  type="stop"
                  onClick={() => this.stopThemeScrapper(theme)}
                />
              </Tooltip>
              <div
                className={`configure-wrapper ${
                  this.state.configure ? "active" : ""
                }`}
                onClick={() => {
                  this.setState({
                    configure: !this.state.configure,
                  });
                  this.props.reloadSingleTheme(this.themeId);
                }}
              >
                <Tooltip title="Configure">
                  <Icon type="setting" theme="filled" />
                </Tooltip>
              </div>
            </Col>
          </Row>
          <Divider style={{ margin: "18px 0" }} />
          <Row>
            <Col>
              {this.state.configure ? (
                <ActionStepsEditor
                  isTheme={true}
                  isJS={theme.javascript}
                  actionSteps={theme.actionSteps}
                  jsToggle={this.toggleJS}
                  saveSteps={this.saveSteps}
                />
              ) : (
                <DataTableComponent
                  isTheme={true}
                  apiProcessing={this.props.apiProcessing}
                  data={data}
                  handePageChange={this.handePageChange}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      ...state.App,
      ...state.Themes,
    }),
    {
      ...themeActions,
    }
  )(ThemeDetailComponent)
);
