import React from "react";
import { Form, Input, Button, Spin } from "antd";
import { connect } from "react-redux";

class ThemeForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.props.onSubmit) this.props.onSubmit(values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { apiProcessing, theme } = this.props;
    return (
      <Form onSubmit={this.handleSubmit} className="theme-form">
        <Form.Item label="Theme Name">
          {getFieldDecorator("themeName", {
            rules: [{ required: true, message: "Please input name of theme!" }],
            initialValue: theme.themeName,
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Listing Path">
          {getFieldDecorator("listingPath", {
            rules: [
              {
                required: true,
                message: "Please input prefix url of listing pages!",
              },
            ],
            initialValue: theme.listingPath,
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Theme Detector">
          {getFieldDecorator("themeDetector", {
            initialValue: theme.themeDetector,
          })(<Input />)}
        </Form.Item>
        <Form.Item>
          <Spin tip="Loading..." spinning={apiProcessing}>
            <Button
              type="primary"
              htmlType="submit"
              className="form-button"
              disabled={apiProcessing}
            >
              {theme._id ? "Update" : "Create"}
            </Button>
          </Spin>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedThemeForm = Form.create({
  name: "theme_form",
})(ThemeForm);

export default connect(
  (state) => ({
    ...state.App,
  }),
  {}
)(WrappedThemeForm);
