import React from "react";
import {
  Typography,
  Row,
  Col,
  Button,
  Tooltip,
  notification,
  Table,
  Icon,
  Modal,
  Upload,
} from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ThemeForm from "../../components/theme-form";
import themeActions from "../../store/themes/action";
import settings from "../../store/setting";
import "./style.scss";

const { Title } = Typography;
const { confirm } = Modal;

class ThemesListComponent extends React.Component {
  state = {
    themeModalVisible: false,
    deleteModalVisible: false,
    uploadUrlModalVisible: false,
    selectedTheme: {},
    pagination: { current: 1 },
  };

  uploadProps = {
    accept: ".csv",
    action: `${settings.apiUrl}api/theme/`,
    showUploadList: false,
    headers: {
      Authorization: "Bearer " + window.localStorage.getItem("token"),
    },
    onChange: (ev) => {
      console.log("file upload  ", ev);
      if (ev.file.status === "done") {
        notification.success({
          message: "CSV Uploaded.",
          description: (
            <div>
              Added &nbsp;
              <strong>
                {ev.file.response.filter((item) => !item.message).length}
              </strong>{" "}
              new sites!
            </div>
          ),
        });
        this.handePageChange(this.state.pagination);
      }
    },
  };

  columns = [
    {
      title: "Theme Name",
      dataIndex: "themeName",
    },
    {
      title: "Total Sites",
      dataIndex: "siteSummary.totalCount",
    },
    {
      title: "Sites running",
      dataIndex: "siteSummary.runningCount",
    },
    {
      title: "Sites queued",
      dataIndex: "siteSummary.queueCount",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (text) => {
        return new Date(text).toLocaleString();
      },
    },
    {
      title: "Action Steps (length)",
      dataIndex: "actionSteps",
      render: (steps) => {
        return steps && steps.length > 0 ? steps.length : "Not defined";
      },
    },
    {
      title: "Cars Count",
      dataIndex: "dataCount",
    },
    {
      title: "Action",
      dataIndex: "",
      className: "action-wrapper",
      key: "x",
      render: (theme) => {
        let singleUploadProps = { ...this.uploadProps };
        singleUploadProps.action =
          singleUploadProps.action + theme._id + "/import";
        return (
          <div>
            {theme.status === "running" && (
              <Tooltip title="Running">
                <Icon
                  type="stop"
                  onClick={() => this.stopThemeScrapper(theme)}
                />
              </Tooltip>
            )}
            {theme.status !== "draft" &&
              theme.status !== "running" &&
              theme.actionSteps.length > 0 && (
                <Tooltip title="Run">
                  <Icon
                    type="caret-right"
                    onClick={() => this.startThemeScrapper(theme)}
                  />
                </Tooltip>
              )}
            <Upload {...singleUploadProps}>
              <Tooltip title="Upload Urls">
                <Icon type="cloud-upload" />
              </Tooltip>
            </Upload>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Tooltip title="Edit">
              <Icon type="edit" onClick={() => this.editTheme(theme)} />
            </Tooltip>
            <Tooltip title="View Detail">
              <Link to={`/themes/${theme._id}`}>
                <Icon type="eye" />
              </Link>
            </Tooltip>
            <Tooltip title="Delete">
              <Icon type="delete" onClick={() => this.deleteTheme(theme)} />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  // rowSelection object indicates the need for row selection
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.status === "running", // Column configuration not to be checked
      name: record.name,
    }),
  };

  componentDidMount() {
    this.props.getThemes();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.apiProcessing && !nextProps.apiProcessing) {
      if (this.state.themeModalVisible)
        this.setState({
          themeModalVisible: false,
        });
    }
  }

  onThemeSubmit = (theme) => {
    let new_theme = {
      ...this.state.selectedTheme,
      ...theme,
    };
    if (new_theme._id) this.props.updateTheme(new_theme);
    else this.props.createTheme(new_theme);
  };

  editTheme = (theme) => {
    this.setState({
      selectedTheme: theme,
      themeModalVisible: true,
    });
  };

  uploadUrls = (theme) => {
    this.setState({
      selectedTheme: theme,
      uploadUrlModalVisible: true,
    });
  };

  startThemeScrapper = (theme) => {
    this.props.startThemeScrapper(theme._id);
  };

  stopThemeScrapper = (theme) => {
    this.props.stopThemeScrapper(theme._id);
  };

  createTheme = () => {
    this.setState({
      selectedTheme: {},
      themeModalVisible: true,
    });
  };

  deleteTheme = (theme) => {
    let description = "";
    let self = this;
    if (theme.actionSteps && theme.actionSteps.length > 0)
      description =
        "This themes includes scrapping steps already setup. Deleteing this theme will result in removal of all actionsteps information and scrapped data!";
    else description = "";
    confirm({
      title: "Are you sure delete this theme?",
      content: description,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        self.props.deleteTheme(theme);
      },
      onCancel() {},
    });
  };

  handePageChange = (pagination) => {
    this.props.getThemes(
      (pagination.current - 1) * settings.pageSize,
      settings.pageSize
    );
    this.setState({ pagination });
  };

  render() {
    const { themes, apiProcessing, themesCount } = this.props;
    return (
      <Row className="themes-listing">
        <Col>
          <div className="themes-topbar">
            <div>
              <Title level={3}>
                {" "}
                Themes
                <Icon
                  className="themes-reload"
                  type="reload"
                  spin={apiProcessing}
                  onClick={() => {
                    this.handePageChange(this.state.pagination);
                  }}
                />
              </Title>
            </div>
            <Button type="primary" onClick={this.createTheme}>
              {" "}
              <Icon type="plus" /> Add New
            </Button>
          </div>
          <Table
            rowKey="_id"
            pagination={{
              total: themesCount,
              pageSize: settings.pageSize,
            }}
            scroll={{ x: true }}
            onChange={this.handePageChange}
            columns={this.columns}
            dataSource={themes}
          />
        </Col>

        <Modal
          title={this.state.selectedTheme._id ? "Update Theme" : "Create Theme"}
          visible={this.state.themeModalVisible}
          onCancel={() => {
            this.setState({ themeModalVisible: false });
          }}
          footer={null}
          bodyStyle={{ paddingBottom: "1px" }}
        >
          <ThemeForm
            key={this.state.themeModalVisible}
            onSubmit={this.onThemeSubmit}
            theme={this.state.selectedTheme}
          />
        </Modal>

        <Modal
          title={`Upload urls to ${this.state.selectedTheme.themeName}`}
          visible={this.state.uploadUrlModalVisible}
          onCancel={() => {
            this.setState({ uploadUrlModalVisible: false });
          }}
          onOk={() => {}}
        ></Modal>
      </Row>
    );
  }
}

export default connect(
  (state) => ({
    ...state.App,
    ...state.Themes,
  }),
  {
    ...themeActions,
  }
)(ThemesListComponent);
