const actions = {
  CHECK_AUTHORIZATION: "CHECK_AUTHORIZATION",
  SIGNIN: "SIGNIN",
  SIGNOUT: "SIGNOUT",
  SIGNUP: "SIGNUP",
  SIGNIN_SUCCESS: "SIGNIN_SUCCESS",
  AUTH_ERROR: "SIGNIN_ERROR",
  GET_USER: "GET_USER",
  SET_USER: "SET_USER",

  signin:
    ({ username, password }) =>
    (dispatch) => {
      dispatch({ type: actions.SIGNIN, username, password });
    },

  signup: (username, password) => (dispatch) => {
    dispatch({ type: actions.SIGNUP, username, password });
  },

  signout: () => (dispatch) => {
    dispatch({ type: actions.SIGNOUT });
  },

  forgotPasswordRequest: (username) => (dispatch) => {
    dispatch({ type: actions.FORGOT_PASSWORD_REQUEST, username });
  },

  forgotPasswordReset: (username, code, password) => (dispatch) => {
    dispatch({ type: actions.FORGOT_PASSWORD_RESET, username, code, password });
  },

  getUser: () => (dispatch) => {
    dispatch({ type: actions.GET_USER });
  },
};
export default actions;
