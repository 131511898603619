import actions from "./action";

const initialState = {
  themes: [],
  data: [],
  theme: {},
  activeTheme: {},
  themesCount: 0,
};

export default function themesReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_THEMES:
      return {
        ...state,
        themes: action.themes,
        themesCount: action.count || state.themesCount,
      };
    case actions.SET_DATA:
      return {
        ...state,
        data: action.data,
      };
    case actions.SET_ACTIVE_THEME:
      return {
        ...state,
        activeTheme: action.theme,
      };
    default:
      return state;
  }
}
