import { all, takeLatest, put, call } from "redux-saga/effects";
import summaryActions from "./action";
import appActions from "../app/action";
import { apiCall } from "../http";
import { summaryUrl } from "../../utils/summaryUrls";
import { singleUserUrl } from "../../utils/authUrls";
import setting from "../setting";

export function* getSummary({ skip, limit }) {
  yield put({ type: appActions.API_CALL_START });
  try {
    const user_id = window.localStorage.getItem("user-id");
    const response = yield call(() => apiCall(summaryUrl()));
    const user_res = yield call(() =>
      fetch(setting.apiUrl + singleUserUrl(user_id)).then((res) => res.json())
    );
    yield put({
      type: summaryActions.SET_SUMMARY,
      data: {
        ...response,
        timeout: user_res.timeout,
        offS3: user_res.offS3,
      },
    });
  } catch (e) {}
  yield put({ type: appActions.API_CALL_END });
}

export function* getTimeout({ userId }) {
  const res = yield call(() =>
    fetch(setting.apiUrl + singleUserUrl(userId)).then((res) => res.json())
  );
  yield put({
    type: summaryActions.SET_TIMEOUT_RESULT,
    timeout: res.timeout,
  });
}

export function* setTimeout({ timeout, userId }) {
  const res = yield call(() =>
    fetch(setting.apiUrl + singleUserUrl(userId), {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ timeout }),
    }).then((res) => res.json())
  );
  yield put({
    type: summaryActions.SET_TIMEOUT_RESULT,
    timeout: res.timeout,
  });
}

export function* setOffS3({ offS3, userId }) {
  const res = yield call(() =>
    fetch(setting.apiUrl + singleUserUrl(userId), {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ offS3 }),
    }).then((res) => res.json())
  );
  yield put({
    type: summaryActions.SET_OFFS3_RESULT,
    offS3: res.offS3,
  });
}

export default function* rootSaga() {
  yield all([
    yield takeLatest(summaryActions.GET_SUMMARY, getSummary),
    yield takeLatest(summaryActions.GET_TIMEOUT, getTimeout),
    yield takeLatest(summaryActions.SET_TIMEOUT, setTimeout),
    yield takeLatest(summaryActions.SET_OFFS3, setOffS3),
  ]);
}
