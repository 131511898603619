import React from "react";
import { Layout, Icon, Menu, Switch } from "antd";
import { connect } from "react-redux";
import authActions from "../../store/auth/action";
import appActions from "../../store/app/action";
import "./style.scss";

const { Header } = Layout;

class TopBar extends React.Component {
  render() {
    let { showNotification } = this.props;
    return (
      <Header className="global-header">
        <div>
          <label>Show Notification</label>
          &nbsp;&nbsp;&nbsp;
          <Switch
            checked={showNotification}
            onChange={this.props.toggleShowNotification}
          />
        </div>
        <Menu
          className="header-menu"
          mode="horizontal"
          defaultSelectedKeys={["2"]}
          style={{ lineHeight: "64px", textAlign: "right" }}
        >
          <Menu.Item key="3" onClick={this.props.signout}>
            <Icon type="logout" />
          </Menu.Item>
        </Menu>
      </Header>
    );
  }
}

export default connect(
  (state) => ({
    ...state.App,
  }),
  {
    ...authActions,
    ...appActions,
  }
)(TopBar);
