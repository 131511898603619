import React from "react";
import {
  Row,
  Col,
  Select,
  Typography,
  Modal,
  notification,
  Input,
  Divider,
  Button,
  Icon,
  Switch,
} from "antd";
import AceEditor from "react-ace";
import { GojsDiagram } from "react-gojs";
import settings from "../../store/setting";
import "./style.scss";

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";

const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography;
const { confirm } = Modal;

const defaultCode = `import scrapy
import json
import csv
import re
import os
from chainxy import db
import requests
from scrapy.spiders import Spider
from scrapy.http import FormRequest
from scrapy.http import Request
from scrapy.selector import HtmlXPathSelector
from chainxy.items import ChainItem
from urlparse  import urlparse

url_regex = re.compile(
    r'^(?:http|ftp)s?://' # http:// or https://
    r'(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\\.?)|' #domain...
    r'localhost|' #localhost...
    r'\\d{1,3}\\.\d{1,3}\\.\\d{1,3}\\.\\d{1,3})' # ...or ip
    r'(?::\\d+)?' # optional port
    r'(?:/?|[/?]\\S+)$', re.IGNORECASE)


# Scrapy crawl scrapper -a is_test=<True/False> -a site=<ObjectID>

class CarSpider(scrapy.Spider):
    name = "spider"
    actions = {}
    # domain = ''
    is_test = True
    starter_idx = 0

    def __init__(self, is_test, site, site_name, timeout, theme_test=False):
        self.is_test = is_test
        self.site_id = site
        self.site_name = site_name
        self.theme_test = theme_test == 'True'
        self.timeout = timeout


    def start_requests(self):
        yield scrapy.Request(url, callback=self.parse_list)

    def parse_list(self, response):
        pass

    # -- Your code goes here --

    def validate(self, xpath_obj):
        try:
            return xpath_obj.extract_first().strip()
        except:
            return ""

'''
# All Item FIelds

item = ChainItem()

item["site_id"] = ""
item["headline"] = ""
item["make"] = ""
item["model"] = ""
item["price"] = ""
item["mileage"] = ""
item["color"] = ""
item["transmission"] = ""
item["year"] = ""
item["doors"] = ""
item["body_type"] = ""
item["dealer"] = ""
item["url"] = response.request.url
item["engine_size"] = ""
item["fuel_type"] = ""
item["vehicle_description"] = ""
item["images"] = "^^^".join([])
item["valid"] = ""
item["dealer_name"] = ""
item["dealer_phone"] = ""
item["dealer_address"] = ""

yield item
'''
        
`;

class ActionStepsEditor extends React.Component {
  state = {
    selectedStep: {},
    steps: [],
    changed: false,
    needSave: false,
    changedSteps: [],
    rawEditModalVisible: false,
    rawStepsString: "",
    themeParams: {},
    code: "",
  };

  stepsDiagram = null;

  enums = {
    type: ["loop", "single", "finish", "done"],
    operation: ["navigate", "save", "click", "scroll", "wait"],
    field_name: settings.fieldNames,
  };

  fields = {
    entry: ["url", "operation", "next", "total_xpath"],
    loop: ["xpath", "operation", "child", "next"],
    single: ["xpath", "operation", "field_name", "next"],
    finish: ["next"],
    done: [],
  };

  createDiagram = (diagramId) => {
    const { go } = window;
    const $ = go.GraphObject.make;

    const myDiagram = $(go.Diagram, diagramId, {
      initialContentAlignment: go.Spot.Center,
      layout: $(
        go.TreeLayout, // specify a Diagram.layout that arranges trees
        { angle: 90, layerSpacing: 55 }
      ),
    });

    myDiagram.nodeTemplate = $(
      go.Node,
      "Vertical",
      {
        alignment: go.Spot.Left,
        click: this.nodeClicked,
        selectionAdornmentTemplate: $(
          go.Adornment,
          "Spot",
          $(
            go.Panel,
            "Auto",
            // this Adornment has a rectangular blue Shape around the selected node
            $(go.Shape, { fill: null, strokeWidth: 0 }),
            $(go.Placeholder)
          ),
          // and this Adornment has a Button to the right of the selected node
          $(
            go.Panel,
            "Horizontal",
            { alignment: go.Spot.Bottom, alignmentFocus: go.Spot.Top },
            $(
              "Button",
              {
                alignment: go.Spot.Bottom,
                alignmentFocus: go.Spot.Top,
                click: () => {
                  this.addNewStep("next");
                },
              }, // define click behavior for Button in Adornment
              new go.Binding("visible", "nextVisible"),
              $(
                go.TextBlock,
                "Next", // the Button content
                { font: "bold 6pt sans-serif" }
              )
            ),
            $(
              "Button",
              {
                alignment: go.Spot.BottomRight,
                alignmentFocus: go.Spot.Top,
                click: () => {
                  this.addNewStep("child");
                },
              }, // define click behavior for Button in Adornment
              new go.Binding("visible", "childVisible"),
              $(
                go.TextBlock,
                "Child", // the Button content
                { font: "bold 6pt sans-serif" }
              )
            )
          )
        ), // end Adornment
      },
      new go.Binding("background", "color"),
      $(
        go.TextBlock,
        {
          textAlign: "left",
          margin: 10,
          font: "600 15px /2 Courier",
          alignment: go.Spot.Left,
        },
        new go.Binding("text", "title")
      ),
      $(
        go.TextBlock,
        {
          textAlign: "left",
          margin: 10,
          font: "400 15px /2 Courier",
          alignment: go.Spot.Left,
        },
        new go.Binding("text", "text"),
        new go.Binding("visible", "detailVisible")
      )
    );

    myDiagram.linkTemplate = $(
      go.Link,
      {
        routing: go.Link.AvoidsNodes, // Orthogonal routing
        corner: 10,
        curve: go.Link.JumpOver,
      }, // with rounded corners
      $(go.Shape, new go.Binding("stroke", "color")),
      $(
        go.Shape,
        { toArrow: "Standard" },
        new go.Binding("stroke", "color"),
        new go.Binding("fill", "color")
      )
    );

    this.stepsDiagram = myDiagram;
    return myDiagram;
  };

  nodeClicked = (ev, obj) => {
    let node = obj.part;
    this.setState({
      selectedStep: node.data.rawStep,
      changed: false,
    });
  };

  updateCurrentStep = () => {
    let newSteps = [...this.state.steps];
    let newStep = { ...this.state.selectedStep };
    let newChangedSteps = [...this.state.changedSteps];
    if (newStep.operation !== "save") newStep.field_name = undefined;
    newSteps.map((s, idx) => {
      if (s.id === newStep.id) newSteps[idx] = newStep;
      return null;
    });
    if (newChangedSteps.indexOf(newStep.id) === -1)
      newChangedSteps.push(newStep.id);
    this.setState({
      steps: newSteps,
      selectedStep: newStep,
      changed: false,
      needSave: true,
      changedSteps: newChangedSteps,
    });
  };

  removeCurrentStep = () => {
    let newSteps = [...this.state.steps];
    let deleteIds = [this.state.selectedStep.id];
    newSteps.map((s, idx) => {
      if (s.next === this.state.selectedStep.id) newSteps[idx].next = undefined;
      if (s.child === this.state.selectedStep.id)
        newSteps[idx].child = undefined;
      return null;
    });
    while (deleteIds.length > 0) {
      let d_id = deleteIds.shift(),
        d_idx = -1;
      let site =
        newSteps.filter((s, idx) => {
          if (s.id === d_id) d_idx = idx;
          return s.id === d_id;
        })[0] || {};
      newSteps.splice(d_idx, 1);
      if (site.next) deleteIds.push(site.next);
      if (site.child) deleteIds.push(site.child);
    }
    this.setState({
      steps: newSteps,
      selectedStep: {},
      needSave: true,
    });
  };

  modelChangedhandler = (params) => {};

  updateDiagramProps = (params) => {};

  getNextId = () => {
    let ids = this.state.steps.map((s) => s.id);
    return Math.max(...ids) + 1;
  };

  calculateModel = () => {
    const { go } = window;
    const $ = go.GraphObject.make;
    let { steps } = this.state;
    let { fromTheme } = this.props;
    let links = [];
    let nodes = steps.map((s) => {
      if (s.next)
        links.push({
          from: s.id,
          to: s.next,
          color: "darkblue",
        });
      if (s.child)
        links.push({
          from: s.id,
          to: s.child,
          color: "red",
        });
      let color =
        this.state.changedSteps.indexOf(s.id) !== -1 ? "orange" : "lightblue";
      if (s.id === this.state.selectedStep.id) color = "yellow";
      let text = "",
        spacer = "",
        title = "",
        detailVisible = false;
      title += `${s.id}:  ${s.type}`;
      spacer = `${s.id}`
        .split("")
        .map((i) => " ")
        .join("");
      if (s.operation) text += `Op:${spacer}${s.operation}\n`;
      if (s.field_name) text += `Fd:${spacer}${s.field_name}\n`;
      if (s.description) text += `\n${s.description}\n`;
      detailVisible = !!s.operation || !!s.field_name || !!s.description;
      return {
        rawStep: s,
        key: s.id,
        title: title,
        text: text.trim(`\n`),
        detailVisible: detailVisible,
        color: color,
        nextVisible:
          !fromTheme &&
          s.next === undefined &&
          this.fields[s.type].indexOf("next") !== -1,
        childVisible:
          !fromTheme &&
          s.child === undefined &&
          this.fields[s.type].indexOf("child") !== -1,
      };
    });
    var model = $(go.GraphLinksModel);
    model.nodeDataArray = nodes;
    model.linkDataArray = links;
    return model;
  };

  addNewStep = (field) => {
    let newId = this.getNextId();
    let newStep = {
      id: newId,
      type: "single",
    };
    let currentStep = this.state.selectedStep;
    let newSteps = [...this.state.steps];
    newSteps.map((s, idx) => {
      if (s.id === currentStep.id) newSteps[idx][field] = newId;
      return null;
    });
    newSteps.push(newStep);
    this.setState({
      steps: newSteps,
      changedSteps: this.state.changedSteps.concat(newId),
      needSave: true,
    });
    if (this.stepsDiagram) this.stepsDiagram.clearSelection();
  };

  saveSteps = () => {
    if (this.props.saveSteps) {
      let newSteps = this.state.steps.map((s) => {
        if (s.operation === "") {
          delete s.operation;
        }
        return s;
      });
      this.props.saveSteps(newSteps);
      this.setState({
        needSave: false,
        changedSteps: [],
      });
    }
  };

  saveCode = (code) => {
    if (this.props.saveCode) {
      this.props.saveCode(code);
      this.setState({
        needSave: false,
      });
    }
  };

  openRawEditModal = () => {
    this.setState({
      rawStepsString: JSON.stringify(this.state.steps, null, 4),
      rawEditModalVisible: true,
    });
  };

  closeRawEditModal = () => {
    this.setState({
      rawEditModalVisible: false,
    });
  };

  updateStepsRaw = () => {
    let newSteps = {};
    try {
      newSteps = JSON.parse(this.state.rawStepsString);
    } catch (e) {
      notification.error({
        message: "Input is not JSON serializable",
      });
      return;
    }
    this.setState({
      steps: newSteps,
      selectedStep: {},
      changedSteps: [],
      needSave: true,
      changed: false,
      rawEditModalVisible: false,
    });
  };

  ejectTheme = () => {
    let { fromTheme } = this.props;
    confirm({
      title: `Do you really want to eject this site from ${fromTheme}?`,
      content: `Action Steps you see will be saved as it is but it will no longer be inhreited from theme`,
      onOk() {},
      onCancel() {},
    });
  };

  updateThemeParams = () => {
    if (this.props.saveThemeParams)
      this.props.saveThemeParams(this.state.themeParams);
    this.setState({
      needSave: false,
    });
  };

  componentDidMount() {
    let steps = JSON.parse(JSON.stringify(this.props.actionSteps));
    let { themeParams } = this.props;
    if (steps.length === 0) steps = [{ id: 1, type: "entry" }];
    this.setState({
      steps: steps,
      code: this.props.code || defaultCode,
      themeParams: themeParams || {},
    });
    this.codeSaverHandle = null;
  }

  render() {
    let { isRunning, isTheme, fromTheme, isJS } = this.props;
    let { steps, needSave, code } = this.state;
    let stepsModel = this.calculateModel();
    let fields_already_set = this.state.steps
      .map((s) => s.field_name)
      .filter((i) => i);
    let themeParamFields = ["url", "prefix", "total_xpath"];

    console.log(steps, stepsModel);

    let goJSDiagram = null;
    try {
      goJSDiagram = isJS ? (
        <AceEditor
          mode="python"
          theme="github"
          name="code-editor"
          width="100%"
          readOnly={isRunning}
          onChange={(v) => {
            this.setState({
              code: v,
              needSave: true,
            });
            if (this.codeSaverHandle) {
              clearTimeout(this.codeSaverHandle);
            }
            this.codeSaverHandle = setTimeout(() => {
              this.saveCode(v);
              this.setState({
                needSave: false,
              });
            }, 1000);
          }}
          value={code}
          fontSize={18}
          enableBasicAutocompletion={true}
        />
      ) : (
        <GojsDiagram
          className="diagram-viewer"
          diagramId="myDiagramDiv"
          model={stepsModel}
          linkKeyProperty="key"
          createDiagram={this.createDiagram}
          onModelChange={this.modelChangedhandler}
          updateDiagramProps={this.updateDiagramProps}
        />
      );
    } catch (e) {
      goJSDiagram = "...";
      console.error(e);
    }
    return (
      <Row className="action-step-editor" type="flex">
        <Col span={24} className="title-bar">
          <Title level={3}>
            Action Steps
            <Icon
              className="steps-reset"
              type="reload"
              onClick={() => {
                this.setState({
                  steps: JSON.parse(JSON.stringify(this.props.actionSteps)),
                  selectedStep: {},
                  changedSteps: [],
                  needSave: false,
                  changed: false,
                });
              }}
            />
          </Title>
          <div className="js-toggle">
            <Switch
              disabled={!!fromTheme || isRunning}
              checked={isJS}
              onChange={this.props.jsToggle}
            />
            <span> Javascript Site </span>
          </div>
          <Icon
            className="raw-edit-toggle"
            type="edit"
            onClick={this.openRawEditModal}
          />
        </Col>
        <Col lg={18}>{goJSDiagram}</Col>
        {fromTheme ? (
          <Col lg={6} className="step-item-form">
            <Divider />
            <Row>
              <Col offset={1}>
                Inhreited from theme <strong>{fromTheme}</strong>
              </Col>
            </Row>
            <Divider />
            {themeParamFields.map((key) => {
              return [
                <Row key={key}>
                  <Col span={7} offset={1}>
                    <label>{key}</label>
                  </Col>
                  <Col span={15}>
                    <Input
                      value={this.state.themeParams[key]}
                      onChange={(ev) => {
                        this.setState({
                          themeParams: {
                            ...this.state.themeParams,
                            [key]: ev.target.value,
                          },
                          needSave: true,
                        });
                      }}
                    />
                  </Col>
                </Row>,
                <Divider key={`divier-${key}`} />,
              ];
            })}
            <Row>
              <Col offset={1} style={{ textAlign: "right" }}>
                <Button
                  disabled={!needSave}
                  type="primary"
                  onClick={this.updateThemeParams}
                >
                  Update Params
                </Button>
              </Col>
            </Row>
            <div className="spacer"></div>
            <Row>
              <Col offset={1} style={{ textAlign: "right" }}>
                <Button type="danger" onClick={this.ejectTheme}>
                  Eject from Theme
                </Button>
              </Col>
            </Row>
            <Divider />
          </Col>
        ) : this.state.selectedStep.id !== undefined ? (
          <Col
            lg={6}
            key={this.state.selectedStep.id}
            className="step-item-form"
          >
            <Divider style={{ marginTop: 0 }} />
            <Row>
              <Col span={7} offset={1}>
                <label>type</label>
              </Col>
              <Col span={15}>
                <Select
                  disabled={
                    !(
                      this.state.selectedStep.child === undefined &&
                      this.state.selectedStep.next === undefined
                    ) ||
                    this.state.selectedStep.type === "entry" ||
                    isRunning
                  }
                  style={{ width: "100%" }}
                  value={this.state.selectedStep.type}
                  onChange={(v) =>
                    this.setState({
                      selectedStep: {
                        ...this.state.selectedStep,
                        type: v,
                      },
                      changed: true,
                    })
                  }
                >
                  {this.enums.type.map((t) => (
                    <Option key={t} value={t}>
                      {t}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
            {this.fields[this.state.selectedStep.type]
              .filter((f) => ["child"].indexOf(f) === -1)
              .map((f, idx) => {
                if (
                  (this.state.selectedStep.operation !== "save" &&
                    f === "field_name") ||
                  f === "next"
                )
                  return null;
                if (
                  f === "url" &&
                  isTheme &&
                  this.state.selectedStep.type === "entry"
                )
                  return null;
                let items_list = this.enums[f];
                if (f === "field_name")
                  items_list = items_list.filter(
                    (i) => fields_already_set.indexOf(i) === -1
                  );
                return [
                  <Divider key={idx + "-divider"} />,
                  <Row key={idx}>
                    <Col span={7} offset={1}>
                      <label>{f}</label>
                    </Col>
                    <Col span={15}>
                      {["operation", "field_name"].indexOf(f) !== -1 ? (
                        <Select
                          disabled={isRunning}
                          style={{ width: "100%" }}
                          value={this.state.selectedStep[f]}
                          onChange={(v) =>
                            this.setState({
                              selectedStep: {
                                ...this.state.selectedStep,
                                [f]: v,
                              },
                              changed: true,
                            })
                          }
                        >
                          <Option key="none" value={""}>
                            -
                          </Option>
                          {items_list
                            .filter((t) => {
                              if (
                                !isJS &&
                                f === "operation" &&
                                ["click", "scroll", "wait"].includes(t)
                              )
                                return false;
                              return true;
                            })
                            .map((t) => (
                              <Option key={t} value={t}>
                                {t}
                              </Option>
                            ))}
                        </Select>
                      ) : (
                        <Input
                          disabled={isRunning}
                          value={this.state.selectedStep[f]}
                          onChange={(e) => {
                            this.setState({
                              selectedStep: {
                                ...this.state.selectedStep,
                                [f]: e.target.value,
                              },
                              changed: true,
                            });
                          }}
                        />
                      )}
                    </Col>
                  </Row>,
                ];
              })}
            <Divider />
            {this.fields[this.state.selectedStep.type].indexOf("next") !==
              -1 && [
              <Row key="next-selector">
                <Col span={7} offset={1}>
                  <label>Next</label>
                </Col>
                <Col span={15}>
                  <Select
                    disabled={isRunning}
                    style={{ width: "100%" }}
                    value={this.state.selectedStep.next}
                    onChange={(v) =>
                      this.setState({
                        selectedStep: {
                          ...this.state.selectedStep,
                          next: v === "" ? undefined : v,
                        },
                        changed: true,
                      })
                    }
                  >
                    <Option key="none" value={""}>
                      -
                    </Option>
                    {steps.map((s) => (
                      <Option key={s.id} value={s.id}>
                        {s.id} {s.type}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>,
              <Divider key="next-divider" />,
            ]}
            <Row>
              <Col span={7} offset={1}>
                <label>description</label>
              </Col>
              <Col span={15}>
                <TextArea
                  rows={2}
                  value={this.state.selectedStep.description}
                  disabled={!!fromTheme || isRunning}
                  onChange={(e) => {
                    this.setState({
                      selectedStep: {
                        ...this.state.selectedStep,
                        description: e.target.value,
                      },
                      changed: true,
                    });
                  }}
                />
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col offset={1} span={22} className="step-action-wrapper">
                <Button
                  disabled={isRunning || !this.state.changed}
                  type="primary"
                  onClick={this.updateCurrentStep}
                >
                  Update
                </Button>
                {this.state.selectedStep.type !== "entry" && (
                  <Button
                    disabled={isRunning}
                    type="danger"
                    onClick={this.removeCurrentStep}
                  >
                    Remove
                  </Button>
                )}
              </Col>
            </Row>
            <div className="spacer"></div>
            <Divider />
            <Row>
              <Col span={22} offset={1} style={{ textAlign: "right" }}>
                <Button
                  disabled={isRunning || !this.state.needSave}
                  type="primary"
                  onClick={this.saveSteps}
                >
                  Save Steps To Database
                </Button>
              </Col>
            </Row>
            <Divider style={{ marginBottom: 0 }} />
          </Col>
        ) : (
          <Col lg={6} className="step-item-form">
            <Row>
              <Col offset={1}>Select Node from left panel</Col>
            </Row>
            <div className="spacer"></div>
            <Divider />
            <Row>
              <Col span={22} offset={1} style={{ textAlign: "right" }}>
                <Button
                  disabled={isRunning || !this.state.needSave}
                  type="primary"
                  onClick={this.saveSteps}
                >
                  Save Steps To Database
                </Button>
              </Col>
            </Row>
            <Divider style={{ marginBottom: 0 }} />
          </Col>
        )}

        <Modal
          title="Edit JSON"
          className="raw-edit-modal"
          visible={this.state.rawEditModalVisible}
          centered
          width="80%"
          bodyStyle={{ padding: 0 }}
          onOk={this.updateStepsRaw}
          onCancel={this.closeRawEditModal}
        >
          <TextArea
            value={this.state.rawStepsString}
            disabled={!!fromTheme || isRunning}
            rows={20}
            style={{
              fontFamily: "consolas",
              fontWeight: "bold",
              background: "lightgrey",
            }}
            onChange={(ev) => {
              this.setState({
                rawStepsString: ev.target.value,
              });
            }}
          />
        </Modal>
      </Row>
    );
  }
}

export default ActionStepsEditor;
