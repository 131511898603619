import React from "react";
import { Form, Icon, Input, Button, Layout, Row, Col, Spin } from "antd";
import { connect } from "react-redux";
import authActions from "../../store/auth/action";
import logo from "../../assets/logo-dark.png";
import "./style.scss";

const { Content } = Layout;

class NormalLoginForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        this.props.signin(values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { apiProcessing } = this.props;
    return (
      <Content className="auth-screen">
        <Row
          align="middle"
          justify="center"
          type="flex"
          style={{ height: "100vh" }}
        >
          <Col lg={14} offset={1}>
            <img src={logo} alt="Carmart" />
          </Col>
          <Col lg={8} xl={6} offset={1}>
            <Form onSubmit={this.handleSubmit} className="auth-form">
              <Form.Item>
                {getFieldDecorator("username", {
                  rules: [
                    { required: true, message: "Please input your username!" },
                  ],
                })(
                  <Input prefix={<Icon type="user" />} placeholder="Username" />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("password", {
                  rules: [
                    { required: true, message: "Please input your password!" },
                  ],
                })(
                  <Input
                    prefix={<Icon type="lock" />}
                    type="password"
                    placeholder="Password"
                  />
                )}
              </Form.Item>
              <Form.Item>
                {/* {getFieldDecorator('remember', {
                  valuePropName: 'checked',
                  initialValue: true,
                })(<Checkbox>Remember me</Checkbox>)} */}
                {/* <label className='auth-form-forgot' >
                  Forgot password
                </label> */}
                <Spin tip="Loading..." spinning={apiProcessing}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="auth-form-button"
                    disabled={apiProcessing}
                  >
                    Log in
                  </Button>
                </Spin>
                {/* <label className='auth-form-register' >
                  Or <Link to='auth/signup'>register now!</Link>
                </label> */}
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    );
  }
}

const WrappedNormalLoginForm = Form.create({ name: "normal_auth" })(
  NormalLoginForm
);

export default connect(
  (state) => ({
    ...state.App,
    ...state.Auth,
  }),
  {
    ...authActions,
  }
)(WrappedNormalLoginForm);
